import { DatePicker, Select } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import PropTypes from "prop-types";
import React, { Fragment, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Footer } from '../components/layout/Footer';
import Navbar from '../components/layout/Navbar';
import { ExploreAndamanCard, WhyBestCard } from '../components/ui/Card';
import GalleryImage from '../components/ui/Gallery';
import ImageGallery from '../components/ui/ImageGallery';
import { CapModalSlider, FoodModalSlider, GallerySlider, HomeSlider, SwiperSlider } from '../components/ui/SwiperSlider';
import { setFormdata } from './../actions/formActions';
import "./Home.scss";
import VideoPlayer from '../components/ui/VideoPlayer';
import { FloatButton } from 'antd';
import { CommentOutlined, CustomerServiceOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';

const { Option } = Select;

const Home = ({ setFormdata }) => {

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    date: '',
    slot: '',
    travelers: '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  // disable past date in select date dropdown
  dayjs.extend(customParseFormat);
  const disabledDate = (current) => {
    const isTimeAfterThree = dayjs().hour() >= 15
    return isTimeAfterThree ? current < dayjs().endOf('day') : current < dayjs().startOf('day');
  };

  const currentHour = dayjs().hour();
  const generateTimeSlots = () => {

    const availableSlots = [
      "09.00 AM",
      "10.00 AM",
      "11.00 AM",
      "12.00 PM",
      "01.00 PM",
      "02.00 PM",
      "03.00 PM"
    ];
    const isToday = new Date(formData.date).getDate() === dayjs().date();
    let disabledSlots = [];
    if (isToday) {
      if (currentHour < 9) {
        return availableSlots.slice(0, 7);
      }
      if (currentHour < 10 && currentHour >= 9) {
        const disabledSlots = ["09.00 AM"]
        return availableSlots.map(slot => {
          const isDisabled = disabledSlots.includes(slot);
          return (
            <Option key={slot} value={slot} disabled={isDisabled} style={{ backgroundColor: isDisabled ? "#D8D8D8" : "", color: isDisabled ? "gainsboro" : "" }}>
              {slot}
            </Option>
          );
        });
      }
      if (currentHour < 11 && currentHour >= 10) {
        const disabledSlots = ["09.00 AM", "10.00 AM"]
        return availableSlots.map(slot => {
          const isDisabled = disabledSlots.includes(slot);
          return (
            <Option key={slot} value={slot} disabled={isDisabled} style={{ backgroundColor: isDisabled ? "#D8D8D8" : "", color: isDisabled ? "gainsboro" : "" }}>
              {slot}
            </Option>
          );
        });
      }
      if (currentHour < 12 && currentHour >= 11) {
        const disabledSlots = ["09.00 AM", "10.00 AM", "11.00 AM"]
        return availableSlots.map(slot => {
          const isDisabled = disabledSlots.includes(slot);
          return (
            <Option key={slot} value={slot} disabled={isDisabled} style={{ backgroundColor: isDisabled ? "#D8D8D8" : "", color: isDisabled ? "gainsboro" : "" }}>
              {slot}
            </Option>
          );
        });
      }
      if (currentHour < 13 && currentHour >= 12) {
        const disabledSlots = ["09.00 AM", "10.00 AM", "11.00 AM", "12.00 PM"]
        return availableSlots.map(slot => {
          const isDisabled = disabledSlots.includes(slot);
          return (
            <Option key={slot} value={slot} disabled={isDisabled} style={{ backgroundColor: isDisabled ? "#D8D8D8" : "", color: isDisabled ? "gainsboro" : "" }}>
              {slot}
            </Option>
          );
        });
      }
      if (currentHour < 14 && currentHour >= 13) {
        const disabledSlots = ["09.00 AM", "10.00 AM", "11.00 AM", "12.00 PM", "01.00 PM",]
        return availableSlots.map(slot => {
          const isDisabled = disabledSlots.includes(slot);
          return (
            <Option key={slot} value={slot} disabled={isDisabled} style={{ backgroundColor: isDisabled ? "#D8D8D8" : "", color: isDisabled ? "gainsboro" : "" }}>
              {slot}
            </Option>
          );
        });
      }
      if (currentHour < 15 && currentHour >= 14) {
        const disabledSlots = ["09.00 AM", "10.00 AM", "11.00 AM", "12.00 PM", "01.00 PM", "02.00 PM"]
        return availableSlots.map(slot => {
          const isDisabled = disabledSlots.includes(slot);
          return (
            <Option key={slot} value={slot} disabled={isDisabled} style={{ backgroundColor: isDisabled ? "#D8D8D8" : "", color: isDisabled ? "gainsboro" : "" }}>
              {slot}
            </Option>
          );
        });
      }
    }

    return availableSlots.map(slot => {
      const isDisabled = disabledSlots.includes(slot);
      return (
        <Option key={slot} value={slot} disabled={isDisabled} style={{ backgroundColor: isDisabled ? "#D8D8D8" : "", color: isDisabled ? "gainsboro" : "" }}>
          {slot}
        </Option>
      );
    });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = {};

    // Perform your validation checks here
    if (!formData.date) {
      validationErrors.date = 'Date is required';
    }
    if (!formData.slot) {
      validationErrors.slot = 'Slot is required';
    }
    if (!formData.travelers) {
      validationErrors.travelers = 'Number of travelers is required';
    }

    if (Object.keys(validationErrors).length === 0) {
      // Form is valid, you can now submit the data or perform further actions
      console.log('Home:', formData);
      setFormdata(formData)
      navigate("/book-sea-walk-in-andaman");
    } else {
      // Set validation errors
      setErrors(validationErrors);
    }
  };

  const [isFoodModalOpen, setIsFoodModalOpen] = useState(false);
  const [isCapModalOpen, setIsCapModalOpen] = useState(false);

  return (
    <Fragment>
      <Navbar />
      <Helmet>
        <title>Sea Gold Sea Walk at Havelock - Book Sea Walk in Andaman Nicobar Islands</title>
        <meta name="description" content="Sea Walk in Andaman is a Popular Adventure Water Sports Activity, you can enjoy with Friends, Family, Couples, Solo or Group Travel. Book Sea Walk at an affordable cost with us." />
        <link rel='canonical' href='/'></link>
      </Helmet>
      <div className='home' id='home-new'>
        <section id='home_banner'>
          {/* <LazyLoadImage className='home_banner-img' src='/images/contact/andaman-sea-walk-bg-contact.jpg' alt='andaman nicobar islands' loading='eager' title='andaman nicobar islands'></LazyLoadImage> */}
          <h1 className='home-main-title'>ANDAMAN & NICOBAR ISLANDS</h1>
          <p className='home-main-sub-title'>(Biggest Seawalk in India - Sea Gold Sea Walk)</p>
          <HomeSlider />
          <form onSubmit={handleSubmit}>
            <div id="banner-form" className="row p-0">
              <div className="col-md-3 p-2 p-md-3 form-date">
                <DatePicker
                  placeholder="Select Date"
                  format={'DD/MM/YYYY'}
                  placement={'bottomLeft'}
                  style={{ width: '100%' }}
                  value={formData.date}
                  disabledDate={disabledDate}
                  onChange={(value) => handleChange('date', value)}
                />
                {errors.date && <p className="error">* {errors.date}</p>}
              </div>
              <div className="col-md-3 pt-0 pt-md-3 p-2 p-md-3 form-slot">
                <Select
                  placeholder="Choose Slot (Time)"
                  style={{ width: "100%" }}
                  onChange={value => handleChange("slot", value)}
                  disabledDate={disabledDate}
                >
                  {generateTimeSlots()}
                </Select>
                {errors.slot && <p className="error">* {errors.slot}</p>}
              </div>
              <div className="col-md-3 pt-0 pt-md-3 p-2 p-md-3 form-travellers">
                <Select
                  placeholder="No.of Travellers"
                  style={{ width: '100%' }}
                  // value={formData.travelers}
                  onChange={(value) => handleChange('travelers', value)}
                  options={[
                    { value: '01', label: '01' },
                    { value: '02', label: '02' },
                    { value: '03', label: '03' },
                    { value: '04', label: '04' },
                    { value: '05', label: '05' },
                    { value: '06', label: '06' },
                    { value: '07', label: '07' },
                    { value: '08', label: '08' },
                    { value: '09', label: '09' },
                    { value: '10', label: '10' },
                    { value: '11', label: '11' },
                    { value: '12', label: '12' },
                    { value: '13', label: '13' },
                    { value: '14', label: '14' },
                    { value: '15', label: '15' },
                    { value: '16', label: '16' },
                    { value: '17', label: '17' },
                    { value: '18', label: '18' },
                    { value: '19', label: '19' },
                    { value: '20', label: '20' },
                  ]}
                />
                {errors.travelers && <p className="error">* {errors.travelers}</p>}
              </div>
              <div className="col-md-3 p-0 form-button">
                <button type="submit">Book Now</button>
              </div>
            </div>
          </form>
        </section>
        <section id="home_why-best">
          <h1 className='heading text-center'>Why We Are Best?</h1>
          <div className='pt-3 pt-md-5 row m-0'>
            <div className='col-md-6 pb-4 pb-0'>
              <WhyBestCard title="Safety Pioneers" src='/images/home/Safety Pioneers.png' description="Dive with confidence. Unmatched safety standards ensure your adventure is thrilling and secure with Sea Gold Sea Walk." />
            </div>
            <div className='col-md-6 pb-4 pb-0'>
              <WhyBestCard title="Underwater Elegance" src='/images/home/Underwater Elegance.png' description="Glide through the ocean depths surrounded by breathtaking scenery, turning each step into a dance beneath the waves." />
            </div>
            <div className='col-md-6 pb-4 pb-0'>
              <WhyBestCard title="Eco-Friendly Excitement" src='/images/home/Eco-Friendly Excitement.png' description="Sea Gold Sea Walk is your gateway to the underwater world with a commitment to eco-friendly practices and marine conservation." />
            </div>
            <div className='col-md-6 pb-4 pb-0'>
              <WhyBestCard title="All-Inclusive Adventure" src='/images/home/All-Inclusive Adventure.png' description="From seasoned divers to first-timers, Sea Gold Sea Walk welcomes everyone for a tailor-made, inclusive underwater journey." />
            </div>
          </div>
        </section>
        {/* <section id='explore-andaman'>
          <h1 className='heading text-center'>Explore Andaman</h1>
          <div className='pt-3 pt-md-5 row m-0'>
            <div className='col-sm-6 col-md-4 col-lg-3 p-3 ms-auto me-auto'>
              <ExploreAndamanCard src="/images/home/explore-andaman-port-blair.png" alt="Port Blair" name="Port Blair" />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3 p-3 ms-auto me-auto'>
              <ExploreAndamanCard src="/images/home/explore-andaman-havelock-Island.png" alt="Havelock" name="Havelock" />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3 p-3 ms-auto me-auto'>
              <ExploreAndamanCard src="/images/home/explore-andaman-neil.png" alt="Neil Island" name="Neil Island" />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3 p-3 ms-auto me-auto'>
              <ExploreAndamanCard src="/images/home/explore-andaman-little-andaman.jpg" alt="Little Andaman" name="Little Andaman" />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3 p-3 ms-auto me-auto'>
              <ExploreAndamanCard src="/images/home/hutbay.jpg" alt="Hut Bay Island" name="Hut Bay Island" />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3 p-3 ms-auto me-auto'>
              <ExploreAndamanCard src="/images/home/jolly-buoy-island.png" alt="Jolly Buoy Island" name="Jolly Buoy Island" />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3 p-3 ms-auto'>
              <ExploreAndamanCard src="/images/home/ross-island.png" alt="Ross Island" name="Ross Island" />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3 p-3 me-auto'>
              <ExploreAndamanCard src="/images/home/north-bay-island.png" alt="North Bay Island" name="North Bay Island" />
            </div>
          </div>
        </section> */}
        <section id='video-section'>
          {/* <img className='wave' src='/images/home/wave1.jpg' alt="sea walk in andaman"></img> */}
          <h1 className="heading text-center pb-5">
            SeaWalk Walkthrough Video
          </h1>
          <div id='video-player'>
            <VideoPlayer />
          </div>
        </section>
        <section id="application-process">
          <h1 className="heading text-center">
            How to Reach
          </h1>
          <br></br>
          <div className="d-flex flex-wrap justify-content-center align-items-center">
            <div id="process-card">
              <div className="process-card_details">
                <h5 className="text-center">1. India</h5>
                <p className="text-center">Whether you are from India or anyother of part of world take a flight from India to Port Blair, Andaman Islands.</p>
              </div>
            </div>

            <div id="process-card">
              <div className="process-card_details">
                <h5 className="text-center">2. Andaman Nicobar Islands</h5>
                <p className="text-center">From India, take a flight to Port Blair Airport (IXZ) which is the Capital of beautiful Andaman and Nicobar Islands.</p>
              </div>
            </div>

            <div id="process-card">
              <div className="process-card_details">
                <h5 className="text-center">3. Havelock Beach</h5>
                <p className="text-center">To reach Elephant Beach, you need to take a Ferry from Port Blair, Andaman Islands. </p>
              </div>
            </div>

            <div id="process-card">
              <div className="process-card_details">
                <h5 className="text-center">4. Elephant Beach</h5>
                <p className="text-center">From this location, you will be guided by Sea Walk master's to adventure the Sea Walk.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* <ImageGallery /> */}
        <section className='w-100 pt-5' id="gallery-section">
          <h1 className="heading text-center pb-4">
            Gallery
          </h1>
          <div className='p-1' style={{ boxShadow: "0px 2px 50px 3px gray", borderRadius: "10px" }}>
            <GalleryImage />
          </div>
        </section>
        <h1 className="heading text-center pb-4">
          Travellers Reviews
        </h1>
        <section id="testimonials">
          <LazyLoadImage className='testimonials-bg-img' src='/images/home/underwater-world-bg.png' alt='underwater world background'></LazyLoadImage>
          <SwiperSlider />
        </section>
      </div>
      <FloatButton
        shape="circle"
        type="primary"
        style={{ right: 15, bottom: 135 }}
        onClick={() => setIsFoodModalOpen(true)}
        icon={<LazyLoadImage src='images/home/food.png' alt='food menu'></LazyLoadImage>}
        tooltip={<div style={{ color: "white" }}>Food Menu</div>}
      />
      <FloatButton
        shape="circle"
        type="primary"
        style={{ right: 15, bottom: 75 }}
        onClick={() => setIsCapModalOpen(true)}
        icon={<LazyLoadImage src='images/home/cap.png' alt='food menu'></LazyLoadImage>}
        tooltip={<div style={{ color: "white" }}>Free Cap</div>}
      />
      <Modal title="Food Menu" open={isFoodModalOpen} onOk={() => setIsFoodModalOpen(false)} onCancel={() => setIsFoodModalOpen(false)}>
        <FoodModalSlider />
      </Modal>
      <Modal title="Cap Free" open={isCapModalOpen} onOk={() => setIsCapModalOpen(false)} onCancel={() => setIsCapModalOpen(false)}>
        <CapModalSlider />
      </Modal>
      <Footer />
    </Fragment>
  )
}

Home.prototype = {
  setFormdata: PropTypes.func.isRequired
}

export default connect(null, { setFormdata })(Home);