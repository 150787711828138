import { DatePicker, Select } from 'antd';
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Footer } from '../components/layout/Footer';
import Navbar from '../components/layout/Navbar';
import Button from '../components/ui/Button';
import { setFormdata } from './../actions/formActions';
import "./Seawalk.scss";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from 'react-helmet-async'
import { FloatButton } from 'antd';
import { Modal } from 'antd';
import { CapModalSlider, FoodModalSlider } from '../components/ui/SwiperSlider';

const { Option } = Select;

const SeaWalk = ({ formdata, setFormdata }) => {


    const navigate = useNavigate();

    const [activeMenu, setActiveMenu] = useState(null);

    const onClickMenu = (menuId) => {
        setActiveMenu(menuId);
    }

    const [isVisible, setIsVisible] = useState(true);
    const [height, setHeight] = useState(0)

    useEffect(() => {
        window.addEventListener("scroll", listenToScroll);
        return () =>
            window.removeEventListener("scroll", listenToScroll);
    }, [])

    const listenToScroll = () => {
        let heightToHideFrom = 350;
        const winScroll = document.body.scrollTop ||
            document.documentElement.scrollTop;
        setHeight(winScroll);

        if (winScroll > heightToHideFrom) {
            isVisible && setIsVisible(false);
        } else {
            setIsVisible(true);
        }
    };

    const [formData, setFormData] = useState({
        id: uuidv4(),
        date: formdata?.date,
        slot: formdata?.slot,
        travelers: formdata?.travelers,
    });

    // Use useEffect to populate the form fields if formdata from Redux has values
    useEffect(() => {
        if (formdata) {
            setFormData(formdata);
        }
    }, [formdata]);

    const [errors, setErrors] = useState({});

    const handleChange = (field, value) => {
        setFormData({
            ...formData,
            [field]: value,
        });
    };

    // disable past date in select date dropdown
    dayjs.extend(customParseFormat);
    const disabledDate = (current) => {
        const isTimeAfterThree = dayjs().hour() >= 15
        return isTimeAfterThree ? current < dayjs().endOf('day') : current < dayjs().startOf('day');
    };

    const currentHour = dayjs().hour();
    const generateTimeSlots = () => {

        const availableSlots = [
            "09.00 AM",
            "10.00 AM",
            "11.00 AM",
            "12.00 PM",
            "01.00 PM",
            "02.00 PM",
            "03.00 PM"
        ];
        const isToday = new Date(formData.date).getDate() === dayjs().date();
        let disabledSlots = [];
        if (isToday) {
            if (currentHour < 9) {
                return availableSlots.slice(0, 7);
            }
            if (currentHour < 10 && currentHour >= 9) {
                const disabledSlots = ["09.00 AM"]
                return availableSlots.map(slot => {
                    const isDisabled = disabledSlots.includes(slot);
                    return (
                        <Option key={slot} value={slot} disabled={isDisabled} style={{ backgroundColor: isDisabled ? "#D8D8D8" : "", color: isDisabled ? "gainsboro" : "" }}>
                            {slot}
                        </Option>
                    );
                });
            }
            if (currentHour < 11 && currentHour >= 10) {
                const disabledSlots = ["09.00 AM", "10.00 AM"]
                return availableSlots.map(slot => {
                    const isDisabled = disabledSlots.includes(slot);
                    return (
                        <Option key={slot} value={slot} disabled={isDisabled} style={{ backgroundColor: isDisabled ? "#D8D8D8" : "", color: isDisabled ? "gainsboro" : "" }}>
                            {slot}
                        </Option>
                    );
                });
            }
            if (currentHour < 12 && currentHour >= 11) {
                const disabledSlots = ["09.00 AM", "10.00 AM", "11.00 AM"]
                return availableSlots.map(slot => {
                    const isDisabled = disabledSlots.includes(slot);
                    return (
                        <Option key={slot} value={slot} disabled={isDisabled} style={{ backgroundColor: isDisabled ? "#D8D8D8" : "", color: isDisabled ? "gainsboro" : "" }}>
                            {slot}
                        </Option>
                    );
                });
            }
            if (currentHour < 13 && currentHour >= 12) {
                const disabledSlots = ["09.00 AM", "10.00 AM", "11.00 AM", "12.00 PM"]
                return availableSlots.map(slot => {
                    const isDisabled = disabledSlots.includes(slot);
                    return (
                        <Option key={slot} value={slot} disabled={isDisabled} style={{ backgroundColor: isDisabled ? "#D8D8D8" : "", color: isDisabled ? "gainsboro" : "" }}>
                            {slot}
                        </Option>
                    );
                });
            }
            if (currentHour < 14 && currentHour >= 13) {
                const disabledSlots = ["09.00 AM", "10.00 AM", "11.00 AM", "12.00 PM", "01.00 PM",]
                return availableSlots.map(slot => {
                    const isDisabled = disabledSlots.includes(slot);
                    return (
                        <Option key={slot} value={slot} disabled={isDisabled} style={{ backgroundColor: isDisabled ? "#D8D8D8" : "", color: isDisabled ? "gainsboro" : "" }}>
                            {slot}
                        </Option>
                    );
                });
            }
            if (currentHour < 15 && currentHour >= 14) {
                const disabledSlots = ["09.00 AM", "10.00 AM", "11.00 AM", "12.00 PM", "01.00 PM", "02.00 PM"]
                return availableSlots.map(slot => {
                    const isDisabled = disabledSlots.includes(slot);
                    return (
                        <Option key={slot} value={slot} disabled={isDisabled} style={{ backgroundColor: isDisabled ? "#D8D8D8" : "", color: isDisabled ? "gainsboro" : "" }}>
                            {slot}
                        </Option>
                    );
                });
            }
        }

        return availableSlots.map(slot => {
            const isDisabled = disabledSlots.includes(slot);
            return (
                <Option key={slot} value={slot} disabled={isDisabled} style={{ backgroundColor: isDisabled ? "#D8D8D8" : "", color: isDisabled ? "gainsboro" : "" }}>
                    {slot}
                </Option>
            );
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = {};

        // Perform your validation checks here
        if (!formData.date) {
            validationErrors.date = 'Date is required';
        }
        if (!formData.slot) {
            validationErrors.slot = 'Slot is required';
        }
        if (!formData.travelers) {
            validationErrors.travelers = 'Number of travelers is required';
        }

        if (Object.keys(validationErrors).length === 0) {
            // Form is valid, you can now submit the data or perform further actions
            console.log('Seawalk:', formData);
            const bookingId = uuidv4();
            const formDataWithId = {
                ...formData,
                id: bookingId,
            };
            const { id, date, slot, travelers } = formDataWithId;
            localStorage.setItem("booking details", { id, date, slot, travelers })
            setFormdata({ id, date, slot, travelers });
            setFormData({
                id: '',
                date: '',
                slot: '',
                travelers: '',
            })
            navigate("/booking/" + bookingId);
        } else {
            // Set validation errors
            setErrors(validationErrors);
        }
    };

    const [isFoodModalOpen, setIsFoodModalOpen] = useState(false);
    const [isCapModalOpen, setIsCapModalOpen] = useState(false);

    return (
        <Fragment>
            {isVisible && <Navbar />}
            <Helmet>
                <title>Book Sea Walk in Andaman</title>
                <meta name="description" content="This is what you want to show as the page content in the Google SERP Listing" />
                <link rel='canonical' href='/book-sea-walk-in-andaman'></link>
            </Helmet>
            <form onSubmit={handleSubmit}>
                <div style={{ position: "relative" }}>
                    <section id='home_banner'>
                        <LazyLoadImage className='home_banner-img' src='/images/about/andaman-sea-walk-about-bg.png' alt='andaman nicobar islands'></LazyLoadImage>
                        <h1 className='home-main-title'>SEA WALK IN ANDAMAN</h1>
                        <p className='home-main-sub-title'>(Largest Seawalk in India - Sea Gold Sea Walk)</p>
                    </section>

                    <section id='sea-walk'>
                        <div className='row m-0'>
                            <div className='col-lg-8 col-xl-8 p-xl-4'>
                                <h1 className='heading pb-3'>Sea Walking in Andaman Islands</h1>
                                <div className='menu'>
                                    <ul className='p-0 m-0 d-flex'>
                                        <li className='menu-item'>
                                            <a className={activeMenu === 'about' ? 'menu-link active' : 'menu-link'} href='#about' onClick={() => onClickMenu('about')}>About</a>
                                        </li>
                                        <li className='menu-item'>
                                            <a className={activeMenu === 'impInfo' ? 'menu-link active' : 'menu-link'} href='#impInfo' onClick={() => onClickMenu('impInfo')}>Important Info</a>
                                        </li>
                                        <li className='menu-item'>
                                            <a className={activeMenu === 'location' ? 'menu-link active' : 'menu-link'} href='#location' onClick={() => onClickMenu('location')}>Location</a>
                                        </li>
                                        <li className='menu-item'>
                                            <a className={activeMenu === 'customerSupport' ? 'menu-link active' : 'menu-link'} href='#customerSupport' onClick={() => onClickMenu('customerSupport')}>Customer Support</a>
                                        </li>
                                        <li className='menu-item'>
                                            <a className={activeMenu === 'policy' ? 'menu-link active' : 'menu-link'} href='#policy' onClick={() => onClickMenu('policy')}>Policy</a>
                                        </li>
                                    </ul>
                                </div>
                                <br></br>
                                <div id='sea-walk-details'>
                                    <div id='about' className='details-section'>
                                        <h1 className='details_title'>Experience the Mystical Underwater World with Sea Walk in Andaman Islands!</h1>
                                        <p>Are you ready to embark on an unforgettable adventure that will take you deep beneath the surface of the turquoise waters of the Andaman Islands? If so, the Andaman Islands Sea Walk is an experience you don't want to miss. Immerse yourself in the enchanting beauty of the marine world, surrounded by vibrant coral reefs, colorful fish, and the serenity of the ocean's embrace.</p>
                                        <br></br>
                                        <h1 className='details_title'>What is the Sea Walk in Andaman Islands?</h1>
                                        <p>The Sea Walk in Andaman Islands is a unique underwater activity that allows you to explore the captivating marine life without any scuba diving experience. It's a guided tour that takes you on an extraordinary journey, walking along the ocean floor while wearing a specially designed helmet that provides a continuous supply of fresh air.</p>
                                        <br></br>
                                        <h1 className='details_title'>Highlights of the Sea Walk</h1>
                                        <ul className='p-0'>
                                            <li><strong className='details_subTitle'>No Diving Experience Required:<br></br>  </strong> Unlike traditional scuba diving, the Sea Walk in Andaman Islands is suitable for everyone, regardless of age or swimming skills. It's the perfect way for non-divers to discover the wonders of the underwater world.</li>
                                            <li><strong className='details_subTitle'>Spectacular Marine Life:<br></br>  </strong>As you walk along the seabed, you'll come face to face with an incredible array of marine creatures. Get ready to be mesmerized by the vibrant coral formations, playful fish, and other aquatic wonders.</li>
                                            <li><strong className='details_subTitle'>Expert Guides:<br></br>  </strong> Our experienced guides are well-versed in marine life and safety protocols. They'll provide you with all the necessary instructions and guidance to ensure a safe and enjoyable experience.</li>
                                            <li><strong className='details_subTitle'>Photo Opportunities:<br></br>  </strong>Capture the magic of your sea walk with stunning underwater photographs. Our guides are equipped with waterproof cameras to help you preserve these precious moments.</li>
                                            <li><strong className='details_subTitle'>Comfort and Convenience:<br></br>  </strong>The specially designed helmets keep your head dry and allow you to breathe naturally as you walk underwater. You don't need to worry about getting your face wet or dealing with complicated diving equipment.</li>
                                            <li><strong className='details_subTitle'>Environmental Conservation:<br></br>  </strong>We prioritize the conservation of the marine ecosystem. Our guides will provide insights into responsible tourism practices, helping you understand how to protect these delicate underwater habitats.</li>
                                        </ul>
                                    </div>
                                    <div id='impInfo' className='details-section'>
                                        <h1 className='details_title imp-info'>Important Information</h1>
                                        <ul className='p-0'>
                                            <li><strong className='details_subTitle'>Duration:<br></br>  </strong>Approximately <strong>15-25</strong> minutes (actual underwater time may vary).</li>
                                            <li><strong className='details_subTitle'>Age Limit:<br></br>  </strong>Suitable for participants aged <strong>14 up to 50</strong>.</li>
                                            <li><strong className='details_subTitle'>Medical Considerations:<br></br>  </strong>Please consult your doctor if you have any pre-existing medical conditions that could impact your participation.</li>
                                            <li><strong className='details_subTitle' style={{ color: "red" }}><img className='warning-img' src='/images/warning.png'></img> Sea Walk is strictly restricted to any person suffering from: <img className='warning-img' src='/images/warning.png'></img><br></br></strong><ul className='row m-0 warning'>
                                                <li className='col-md-6'>Heart Problem</li>
                                                <li className='col-md-6'>Blood Pressure</li>
                                                <li className='col-md-6'>Chronic Sinusitis</li>
                                                <li className='col-md-6'>Pregnant Women</li>
                                                <li className='col-md-6'>Asthma / Wheezing</li>
                                                <li className='col-md-6'>Any recent surgery</li>
                                                <li className='col-md-6'>Epilepsy / Blackouts</li>
                                                <li className='col-md-6'>Any chronic medical condition</li>
                                            </ul></li>
                                            <li><strong className='details_subTitle'>Safety:<br></br>  </strong>Safety is our top priority. All equipment is regularly inspected, and our guides are trained to handle various situations.</li>
                                        </ul>
                                    </div>
                                    <div id='location' className='details-section'>
                                        <h1 className='details_title'>Location</h1>
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3907.3721484331527!2d92.73658307826045!3d11.668016312591343!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3088950b6dd09d9d%3A0x7e3c83f924312f88!2sAberdeen%20Bazar%2C%20Aberdeen%2C%20Port%20Blair%2C%20Andaman%20and%20Nicobar%20Islands%20744101!5e0!3m2!1sen!2sin!4v1697026936551!5m2!1sen!2sin" width="100%" height="250px" style={{ border: "none" }} allowFullScreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                    </div>
                                    <div id='howToReach' className='details-section'>
                                        <h1 className='details_title'>How to reach ?</h1>
                                        <ul className='p-0'>
                                            <li><strong className='details_subTitle'>Step 1:<br></br>  </strong>Whether you are from India or anyother of part of world take a flight from India to Port Blair, Andaman Islands.</li>
                                            <li><strong className='details_subTitle'>Step 2:<br></br>  </strong>From India, take a flight to Port Blair Airport (IXZ) which is the Capital of beautiful Andaman and Nicobar Islands.</li>
                                            <li><strong className='details_subTitle'>Step 3:<br></br>  </strong>To reach Elephant Beach, you need to take a Ferry from Port Blair, Andaman Islands.</li>
                                            <li><strong className='details_subTitle'>Step 4:<br></br>  </strong>From this location, you will be guided by Sea Walk master's to adventure the Sea Walk.</li>
                                        </ul>
                                    </div>
                                    <div id='customerSupport' className='details-section'>
                                        <h1 className='details_title'>Customer Support</h1>
                                        <p>We're committed to delivering exceptional customer support to enhance your Sea Walk Adventure in Andaman Islands. Our team is here to ensure that your journey into the underwater realm is not only remarkable but also backed by reliable and attentive assistance. Contact us today to begin your seamless sea walk experience and embark on an unforgettable journey beneath the waves.</p>
                                        <div className='d-flex flex-wrap justify-content-center'>
                                            <div className='m-2 p-0'>
                                                <a href="tel:+91 7586904999" style={{ textDecoration: "none" }} className='customer-support'> <i className="fa-solid fa-phone fa-sm me-1" style={{ color: "white" }}></i>
                                                    +91
                                                    75869-04999</a>
                                            </div>
                                            <div className='m-2 p-0'>
                                                <a href="tel:+91 7695095641" style={{ textDecoration: "none" }} className='customer-support'> <i className="fa-solid fa-phone fa-sm me-1" style={{ color: "white" }}></i>
                                                    +91
                                                    76950-95641</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div id='policy' className='details-section'>
                                        <h1 className='details_title'>Policy</h1>
                                        <p>Secure your Sea Gold Sea Walk adventure with a non-refundable deposit. Reschedule might be subject to availability. The full payment confirms your reservation, with details provided in the confirmation email. Note: The initial deposit is non-refundable, and no-shows or cancellations within 7 days receive no refunds. We encourage rescheduling, possible up to 5 days before the original booking date. For assistance, contact our customer service team.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-xl-4 p-xl-4' id='booking-container'>
                                <div id='sticky'>
                                    <div id='sea-walk-booking-section'>
                                        <div>
                                            <div className='price-preview m-3 mb-0'>
                                                <p className='price-preview-text'>Sea Walk @ <span>Rs 3500</span>/person</p>
                                            </div>
                                            <br></br>
                                            <h1 className='details_title text-center'>Book Sea Walk in Andaman</h1>
                                            <div className='p-3 pb-0 form-date'>
                                                <label>Select Date: <span style={{ color: "red" }}>*</span></label>
                                                <DatePicker
                                                    placeholder="Select Date"
                                                    format={'DD/MM/YYYY'}
                                                    placement={'bottomLeft'}
                                                    style={{ width: '100%' }}
                                                    value={formData.date}
                                                    disabledDate={disabledDate}
                                                    onChange={(value) => handleChange('date', value)}
                                                />
                                                {errors.date && <p className="error">* {errors.date}</p>}
                                            </div>
                                            <div className='p-3 pb-0 form-slot'>
                                                <label>Choose Slot (time): <span style={{ color: "red" }}>*</span></label>
                                                <Select
                                                    placeholder="Choose Slot (Time)"
                                                    style={{ width: "100%" }}
                                                    value={formData.slot}
                                                    onChange={value => handleChange("slot", value)}
                                                    disabledDate={disabledDate}
                                                >
                                                    {generateTimeSlots()}
                                                </Select>
                                                {/* <Select
                                                placeholder="Choose Slot (Time)"
                                                style={{ width: "100%" }}
                                                value={formData.slot}
                                                onChange={(value) => handleChange('slot', value)}
                                                options={[
                                                    { value: '09.00 AM', label: '09.00 AM' },
                                                    { value: '10.00 PM', label: '10.00 PM' },
                                                    { value: '11.00 AM', label: '11.00 AM' },
                                                    { value: '12.00 PM', label: '12.00 PM' },
                                                    { value: '01.00 PM', label: '01.00 PM' },
                                                    { value: '02.00 PM', label: '02.00 PM' },
                                                    { value: '03.00 PM', label: '03.00 PM' },
                                                ]}
                                            /> */}
                                                {errors.slot && <p className="error">* {errors.slot}</p>}
                                            </div>
                                            <div className='p-3 pb-0 form-travellers'>
                                                <label>No.of Travellers: <span style={{ color: "red" }}>*</span></label>
                                                <Select
                                                    placeholder="No.of Travellers"
                                                    style={{ width: "100%" }}
                                                    value={formData.travelers}
                                                    onChange={(value) => handleChange('travelers', value)}
                                                    options={[
                                                        { value: '1', label: '01' },
                                                        { value: '2', label: '02' },
                                                        { value: '3', label: '03' },
                                                        { value: '4', label: '04' },
                                                        { value: '5', label: '05' },
                                                        { value: '6', label: '06' },
                                                        { value: '7', label: '07' },
                                                        { value: '8', label: '08' },
                                                        { value: '9', label: '09' },
                                                        { value: '10', label: '10' },
                                                        { value: '11', label: '11' },
                                                        { value: '12', label: '12' },
                                                        { value: '13', label: '13' },
                                                        { value: '14', label: '14' },
                                                        { value: '15', label: '15' },
                                                        { value: '16', label: '16' },
                                                        { value: '17', label: '17' },
                                                        { value: '18', label: '18' },
                                                        { value: '19', label: '19' },
                                                        { value: '20', label: '20' },
                                                    ]}
                                                />
                                                {errors.travelers && <p className="error">* {errors.travelers}</p>}
                                            </div>
                                            {/* <div className='p-3'>
                                        <p className='preview-text'>{formData.selectedDate}</p>
                                        <p className='preview-text'>{formData.slot}</p>
                                        <p className='preview-text'>{formData.noOfTravellers}</p>
                                    </div> */}
                                            <div className='p-3 p-0'>
                                                <Button name="Book Now" type="submit" />
                                            </div>
                                        </div>
                                    </div>
                                    <a id='imp-info-btn' className='w-100 mt-3' href='#impInfo' onClick={() => onClickMenu('impInfo')}>
                                        <h4 className='must-read text-center'>👈 MUST READ</h4>
                                        <a className='imp'>Important Information</a>
                                    </a>
                                </div>

                            </div>
                        </div>
                    </section>
                </div>
                <nav className="fixed-bottom bottom-button">
                    {
                        (formData.date && formData.slot && formData.travelers) ? <Button name="Book Now" type="submit" /> :
                            <a href='#sea-walk-booking-section' className='w-100 h-100'>
                                <Button name="Book Now" type="button" />
                            </a>
                    }
                </nav>
            </form>
            <Footer />
            <FloatButton
                shape="circle"
                type="primary"
                style={{ right: 15, bottom: 135 }}
                onClick={() => setIsFoodModalOpen(true)}
                icon={<LazyLoadImage src='images/home/food.png' alt='food menu'></LazyLoadImage>}
                tooltip={<div style={{ color: "white" }}>Food Menu</div>}
            />
            <FloatButton
                shape="circle"
                type="primary"
                style={{ right: 15, bottom: 75 }}
                onClick={() => setIsCapModalOpen(true)}
                icon={<LazyLoadImage src='images/home/cap.png' alt='food menu'></LazyLoadImage>}
                tooltip={<div style={{ color: "white" }}>Free Cap</div>}
            />
            <Modal title="Food Menu" open={isFoodModalOpen} onOk={() => setIsFoodModalOpen(false)} onCancel={() => setIsFoodModalOpen(false)}>
                <FoodModalSlider />
            </Modal>
            <Modal title="Cap Free" open={isCapModalOpen} onOk={() => setIsCapModalOpen(false)} onCancel={() => setIsCapModalOpen(false)}>
                <CapModalSlider />
            </Modal>
        </Fragment>
    )
}

SeaWalk.prototype = {
    setFormdata: PropTypes.func.isRequired,
    formdata: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
    return {
        formdata: state.formReducer.formdata
    }
}

export default connect(mapStateToProps, { setFormdata })(SeaWalk)