import React, { useEffect, useState } from "react";
import { Form, Input, Button, message, Result } from "antd";
import { connect } from "react-redux";
import { PropTypes } from 'prop-types';
import { verifyOTP } from "../../actions/agent";

const Step1Form = ({ data, onSuccess, verifyOTP, agent: { agentDetails } }) => {

    const [otpVerified, setOtpVerified] = useState(false);

    useEffect(() => {
        if (agentDetails?.status == 'OTP Verified' || agentDetails?.status == 'Verified') {
            setOtpVerified(true);
        }
    }, [agentDetails])

    const onFinish = (values) => {
        if (agentDetails?.status == 'OTP Verified' || agentDetails?.status == 'Verified') {
            // If OTP is verified, proceed to the next step
            onSuccess(values);

        } else {
            // Otherwise, display a message indicating OTP verification success
            verifyOTP({ otp: values.otp, agentId: agentDetails?.id })
            // message.success("OTP Verified Successfully");
        }
    };

    return (
        <Form
            name="step1Form"
            initialValues={data}
            autoComplete="off"
            onFinish={onFinish}
            layout="vertical"
        >
            <div className="pt-3 pb-3">
                {otpVerified ? (
                    // Display a message if OTP is verified
                    <Result
                        status="success"
                        title="OTP Verified Successfully"
                    // subTitle="Thanks for accepting the agreement and wait for onboarding details"
                    />

                ) : <Form.Item
                    label="Enter OTP"
                    name="otp"
                    rules={[
                        { required: true, message: "Please input the 6-digit OTP!" },
                        { len: 6, message: "The OTP must be 6 digits long!" },
                    ]}
                >
                    <Input type="number" />
                </Form.Item>}
            </div>

            <Form.Item>
                <Button type="primary" htmlType="submit" style={{ color: "white" }}>
                    {otpVerified ? "Next" : "Verify OTP"}
                </Button>
            </Form.Item>
        </Form>
    );
};

Step1Form.prototype = {
    verifyOTP: PropTypes.func.isRequired,
    agent: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    agent: state.agent
})

export default connect(mapStateToProps, { verifyOTP })(Step1Form);
