import { SET_FORM_DATA, SET_BOOKING_HISTORY } from './../actions/types';

const initialState = {
    formdata: localStorage.getItem("booking details"),
}

const formReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FORM_DATA:
            localStorage.setItem("booking details", action.payload)
            return {
                ...state,
                formdata: action.payload
            }
        default:
            return state;
    }
}

export default formReducer;