import { v4 as uuidv4 } from 'uuid';
import { AGENT_ERROR, AGENT_LOADED, AGREEMENT_ACCEPTED, AGREEMENT_NOTACCEPTED, OTP_FAILED, OTP_VERIFIED } from "./types";
import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';
import { setAlert } from './alert';

// load agent 
export const getAgent = (agentId) => async (dispatch) => {

    const API = axios.create({
        baseURL: "https://www.seagoldseawalk.com",
    });
    console.log(agentId)
    try {
        const res = await API.get(`https://www.seagoldseawalk.com/user_webservice/Login/getAgentByID/${agentId}`).then((res) => {
            console.log(res)
            if (res?.data) {
                dispatch({
                    type: AGENT_LOADED,
                    payload: res?.data
                })
                console.log("Agent loaded");
            }
        });
    } catch (err) {

        if (!err?.response) {
            console.log("no server response");
        }

        if (err.response.data.error_code == 0) {
            dispatch({
                type: AGENT_ERROR,
            })
            console.log("error", err.response.data.msg); // set alert msg
        }
    }
}

// verify otp
export const verifyOTP = ({ otp, agentId }) => async (dispatch) => {

    const API = axios.create({
        baseURL: "https://www.seagoldseawalk.com",
    });

    console.log({ otp, agentId })

    try {
        const res = await API.post(`https://www.seagoldseawalk.com/user_webservice/Login/verifyOTP`, { otp, agentId }).then((res) => {
            console.log(res)
            if (res?.data.error_code == 1) {
                dispatch({
                    type: OTP_VERIFIED,
                    payload: res?.data.result
                })
                dispatch(setAlert("OTP verified successfully", "success"))
                console.log(res?.data.msg);
            }
        });
    } catch (err) {

        if (!err?.response) {
            console.log("no server response");
        }

        if (err.response.data.error_code == 0) {
            dispatch({
                type: OTP_FAILED,
            })
            err.response.data.msg && dispatch(setAlert(err.response.data.msg, "error"))
        }
    }
}

// Accept Agreement
export const acceptAgreement = ({ agentId, agentName }) => async (dispatch) => {

    const API = axios.create({
        baseURL: "https://www.seagoldseawalk.com",
    });

    try {
        const res = await API.post(`https://www.seagoldseawalk.com/user_webservice/Login/acceptAgreement`, { agentId, agentName }).then((res) => {
            console.log(res)
            if (res?.data.error_code == 1) {
                dispatch({
                    type: AGREEMENT_ACCEPTED,
                    payload: res?.data.result
                })
                dispatch(setAlert("Agreement Accepted successfully", "success"))
                console.log(res?.data.msg);
            }
        });
    } catch (err) {

        if (!err?.response) {
            console.log("no server response");
        }

        if (err.response.data.error_code == 0) {
            dispatch({
                type: AGREEMENT_NOTACCEPTED,
            })
            err.response.data.msg && dispatch(setAlert(err.response.data.msg, "error"))
        }
    }
}
