import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "./SwiperSlider.scss";
// import required modules
import { Autoplay, Navigation, Pagination, Parallax } from 'swiper/modules';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const SwiperSlider = () => {
  return (
    <div>
      <Swiper
        style={{
          '--swiper-navigation-color': '#fff',
          '--swiper-pagination-color': '#fff',
        }}
        speed={600}
        parallax={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        navigation={true}
        modules={[Parallax, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className='slider'>
            <div className='slider-avatar'>
              <LazyLoadImage className='slider-avatar-img' src='/images/home/avatar.png' alt='avatar'></LazyLoadImage>
            </div>
            <div className="title text-center" data-swiper-parallax="-300">
              The Underwater Magic 🌊
            </div>
            {/* <div className="subtitle" data-swiper-parallax="-200">
              The Underwater Magic 🌊
            </div> */}
            <div className='content' data-swiper-parallax="-100">
              <LazyLoadImage className='quote-left' src='/images/home/quote-left.png' alt='quote-left'></LazyLoadImage>
              <p className="text">
                As an avid traveler, Sea Gold Sea Walk in the Andaman Islands was nothing short of enchantment. The sea walking experience was like stepping into a dream - surrounded by vibrant coral gardens and a kaleidoscope of marine life. The professionalism and safety measures taken by the Sea Gold team made this adventure truly unforgettable. Highly recommended for anyone seeking a magical rendezvous with the ocean depths!
              </p>
              <LazyLoadImage className='get-quote' src='/images/home/get-quote.png' alt='quote-left'></LazyLoadImage>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='slider'>
            <div className='slider-avatar'>
              <LazyLoadImage className='slider-avatar-img' src='/images/home/avatar.png' alt='avatar'></LazyLoadImage>
            </div>
            <div className="title text-center" data-swiper-parallax="-300">
              Thrill and Tranquility 🌴⚓
            </div>
            {/* <div className="subtitle" data-swiper-parallax="-200">
              Thrill and Tranquility 🌴⚓
            </div> */}
            <div className='content' data-swiper-parallax="-100">
              <LazyLoadImage className='quote-left' src='/images/home/quote-left.png' alt='quote-left'></LazyLoadImage>
              <p className="text">
                Sea Gold Sea Walk struck the perfect balance between adventure and serenity. The underwater world off the Andaman coast unfolded before my eyes in a symphony of colors. The sea walk itself was a breeze, thanks to the attentive guides and top-notch equipment. If you crave an immersive, soul-soothing experience beneath the waves, Sea Gold is the place to be.
              </p>
              <LazyLoadImage className='get-quote' src='/images/home/get-quote.png' alt='quote-left'></LazyLoadImage>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='slider'>
            <div className='slider-avatar'>
              <LazyLoadImage className='slider-avatar-img' src='/images/home/avatar.png' alt='avatar'></LazyLoadImage>
            </div>
            <div className="title text-center" data-swiper-parallax="-300">
              Unparalleled Sea Adventure 🐠🌊
            </div>
            {/* <div className="subtitle" data-swiper-parallax="-200">
              Unparalleled Sea Adventure 🐠🌊
            </div> */}
            <div className='content' data-swiper-parallax="-100">
              <LazyLoadImage className='quote-left' src='/images/home/quote-left.png' alt='quote-left'></LazyLoadImage>
              <p className="text">
                Sea Gold Sea Walk exceeded all expectations. The sea walk wasn't just a stroll; it was a journey into a mesmerizing aquatic realm. The variety of marine life, from graceful rays to curious fish, made every step an exploration. What sets Sea Gold apart is not just the adventure but the commitment to safety and environmental responsibility. An absolute must-try for water enthusiasts!
              </p>
              <LazyLoadImage className='get-quote' src='/images/home/get-quote.png' alt='quote-left'></LazyLoadImage>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='slider'>
            <div className='slider-avatar'>
              <LazyLoadImage className='slider-avatar-img' src='/images/home/avatar.png' alt='avatar'></LazyLoadImage>
            </div>
            <div className="title text-center" data-swiper-parallax="-300">
              Seamless Experience 🌅🏝️
            </div>
            {/* <div className="subtitle" data-swiper-parallax="-200">
              Seamless Experience 🌅🏝️
            </div> */}
            <div className='content' data-swiper-parallax="-100">
              <LazyLoadImage className='quote-left' src='/images/home/quote-left.png' alt='quote-left'></LazyLoadImage>
              <p className="text">
                Sea Gold Sea Walk made my Andaman trip truly exceptional. From the moment I booked to the final steps underwater, everything was seamless. The staff was warm and welcoming, the equipment top-notch, and the underwater scenery breathtaking. The no-fuss booking process and clear communication added to the overall positive experience. Sea Gold Sea Walk is now a highlight in my travel memories.
              </p>
              <LazyLoadImage className='get-quote' src='/images/home/get-quote.png' alt='quote-left'></LazyLoadImage>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export const GallerySlider = () => {
  return (
    <div id='gallery-swiper'>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        loop={true}
        // pagination={{
        //   clickable: true,
        // }}
        navigation={true}
        modules={[Autoplay, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide><img src='/images/banner/img-10.jpeg' alt='sea walk in andaman'></img></SwiperSlide>
        <SwiperSlide><img src='/images/banner/img-11.jpeg' alt='sea walk in andaman'></img></SwiperSlide>
        <SwiperSlide><img src='/images/banner/img-14.jpeg' alt='sea walk in andaman'></img></SwiperSlide>
        <SwiperSlide><img src='/images/banner/img-15.jpeg' alt='sea walk in andaman'></img></SwiperSlide>
        <SwiperSlide><img src='/images/banner/img-16.jpeg' alt='sea walk in andaman'></img></SwiperSlide>
        <SwiperSlide><img src='/images/banner/img-17.jpeg' alt='sea walk in andaman'></img></SwiperSlide>
        <SwiperSlide><img src='/images/banner/img-18.jpeg' alt='sea walk in andaman'></img></SwiperSlide>
        <SwiperSlide><img src='/images/banner/img-19.jpeg' alt='sea walk in andaman'></img></SwiperSlide>
        <SwiperSlide><img src='/images/banner/img-20.jpeg' alt='sea walk in andaman'></img></SwiperSlide>
        <SwiperSlide><img src='/images/banner/img-21.jpeg' alt='sea walk in andaman'></img></SwiperSlide>
      </Swiper>
    </div>
  );
}

export const HomeSlider = () => {
  return (
    <div id='gallery-swiper' className='home-banner-slider'>
      <Swiper
        slidesPerView={1}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        loop={true}
        // navigation={true}
        modules={[Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide><img src='/images/banner/img-19.jpeg' alt='sea walk in andaman'></img></SwiperSlide>
        <SwiperSlide><img src='/images/banner/img-18.jpeg' alt='sea walk in andaman'></img></SwiperSlide>
        <SwiperSlide><img src='/images/banner/img-15.jpeg' alt='sea walk in andaman'></img></SwiperSlide>
        <SwiperSlide><img src='/images/banner/img-17.jpeg' alt='sea walk in andaman'></img></SwiperSlide>
      </Swiper>
    </div>
  );
}

export const CapModalSlider = () => {
  return (
    <div id='cap-swiper'>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        loop={true}
        // pagination={{
        //   clickable: true,
        // }}
        navigation={true}
        modules={[Autoplay, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide><img className='cap-image' src='/images/home/cap-dark.png' alt='sea walk in andaman'></img></SwiperSlide>
        <SwiperSlide><img className='cap-image' src='/images/home/cap-right-dark.png' alt='sea walk in andaman'></img></SwiperSlide>
        <SwiperSlide><img className='cap-image' src='/images/home/cap-left-dark.png' alt='sea walk in andaman'></img></SwiperSlide>
      </Swiper>
    </div>
  );
}

export const FoodModalSlider = () => {
  return (
    <div id='cap-swiper'>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        loop={true}
        // pagination={{
        //   clickable: true,
        // }}
        navigation={true}
        modules={[Autoplay, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <h5>Food Name</h5>
          <img className='cap-image' src='/images/food/food-1.jpg' alt='sea walk in andaman'></img></SwiperSlide>
        <SwiperSlide>
          <h5>Food Name</h5>
          <img className='cap-image' src='/images/food/food-2.jpg' alt='sea walk in andaman'></img></SwiperSlide>
        <SwiperSlide>
          <h5>Food Name</h5>
          <img className='cap-image' src='/images/food/food-3.jpg' alt='sea walk in andaman'></img></SwiperSlide>
      </Swiper>
    </div>
  );
}