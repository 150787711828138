export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

export const SET_FORM_DATA = "SET_FORM_DATA";
export const SET_REGISTER = "SET_REGISTER";
export const SET_LOGIN = "SET_LOGIN";

export const GUEST_USER = "GUEST_USER";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const AGENT_REQUEST_SENT = "AGENT_REQUEST_SENT";
export const AGENT_REQUEST_FAIL = "AGENT_REQUEST_FAIL";

export const PROFILE_UPDATED = "PROFILE_UPDATED";
export const PROFILE_UPDATED_FAIL = "PROFILE_UPDATED_FAIL";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";

export const SET_BOOKING_HISTORY = "SET_BOOKING_HISTORY";

export const LOGOUT = "LOGOUT";

export const BOOKING_SUCCESS = "BOOKING_SUCCESS";
export const BOOKING_FAIL = "BOOKING_FAIL";

export const GET_BOOKING_HISTORY = "GET_BOOKING_HISTORY";
export const BOOKING_HISTORY_ERROR = "BOOKING_HISTORY_ERROR";

export const GET_BOOKING_DETAILS = "GET_BOOKING_DETAILS";
export const GET_BOOKING_DETAILS_ERROR = "GET_BOOKING_DETAILS_ERROR";

export const ENQUIRY_SENT = "ENQUIRY_SENT";
export const ENQUIRY_FAIL = "ENQUIRY_FAIL";

export const OTP_VERIFIED = "OTP_VERIFIED";
export const OTP_FAILED = "OTP_FAILED";

export const AGENT_LOADED = "AGENT_LOADED";
export const AGENT_ERROR = "AGENT_ERROR";

export const AGREEMENT_ACCEPTED = "AGREEMENT_ACCEPTED";
export const AGREEMENT_NOTACCEPTED = "AGREEMENT_NOTACCEPTED";