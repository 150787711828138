import setAuthToken from "../utils/setAuthToken";
import { loadUser } from "./auth";
import { BOOKING_SUCCESS, BOOKING_FAIL, GET_BOOKING_HISTORY, BOOKING_HISTORY_ERROR, SET_FORM_DATA, GET_BOOKING_DETAILS, GET_BOOKING_DETAILS_ERROR } from "./types";
import axios from 'axios';

// Get Payment Details
export const loadPaymentData = (bookingId) => async (dispatch) => {
    console.log(bookingId);

    const API = axios.create({
        baseURL: "https://www.seagoldseawalk.com",
    });

    try {
        const res = await API.get(`https://www.seagoldseawalk.com/user_webservice/Login/getPaymentDetails/${bookingId}`).then((res) => {

            console.log("redirect redux", res);

            if (res?.data) {
                // dispatch({
                //     type: BOOKING_SUCCESS,
                //     payload: res.data.result
                // })

                dispatch({
                    type: GET_BOOKING_DETAILS,
                    payload: { ...res.data.bookingDetails, ...res.data.userDetails }
                })

                dispatch({
                    type: SET_FORM_DATA,
                    payload: {}
                })
                console.log("Booking success");
            }
        });
    } catch (err) {
        if (!err?.response) {
            console.log("no server response");
        }

        if (err.response.data.error_code == 0) {
            dispatch({
                type: BOOKING_FAIL,
                payload: { msg: err.response.data.msg, status: err.response.status, result: err.response.data.result }
            })
            // window.location.href = `/booking-failure/${err.response.data.result.booking_id}`;
            console.log("Booking failed", err.response.data.msg); // set alert msg
        }
    }
}

// booking details
export const makeBooking = ({ bookingID, seawalkdate, bookedSlot, travelers, totalAmount, travelersDetails }) => async (dispatch) => {

    console.log({ bookingID, seawalkdate, bookedSlot, travelers, totalAmount, travelersDetails })

    const API = axios.create({
        baseURL: "https://www.seagoldseawalk.com",
    });

    const token = localStorage.token;
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const res = await API.post("https://www.seagoldseawalk.com/user_webservice/Login/booking", {
            bookingID, seawalkdate, bookedSlot, travelers, totalAmount, travelersDetails
        }).then((res) => {
            console.log(res);

            if (res?.data.data.instrumentResponse) {

                window.location.href = res.data.data.instrumentResponse.redirectInfo.url;

                setAuthToken(token);

                // dispatch(loadPaymentData())
            }

        });
    } catch (err) {

        if (!err?.response) {
            console.log("no server response");
        }

        if (err.response.data.error_code == 0) {
            dispatch({
                type: BOOKING_FAIL,
                payload: { msg: err.response.data.msg, status: err.response.status }
            })
            console.log("Booking failed", err.response.data.msg); // set alert msg
        }
    }
}


// Get Booking Details by Booking Id
export const getBookingDetailsById = (bookingId) => async (dispatch) => {

    const API = axios.create({
        baseURL: "https://www.seagoldseawalk.com",
    });
    // console.log(bookingId)

    try {

        const res = await API.get(`https://www.seagoldseawalk.com/user_webservice/Login/getBookingDetailsById/${bookingId}`).then((res) => {
            // console.log(res.data.bookingDetails)
            // console.log(res.data.userDetails)
            if (res?.data) {
                dispatch({
                    type: GET_BOOKING_DETAILS,
                    payload: { ...res.data.bookingDetails, ...res.data.userDetails }
                })
                dispatch(loadUser())
            }
        });
    } catch (err) {

        if (!err?.response) {
            console.log("no server response");
        }

        if (err.response.data.error_code == 0) {
            dispatch({
                type: GET_BOOKING_DETAILS_ERROR,
                payload: { msg: err.response.data.msg, status: err.response.status }
            })
        }
    }
}


// booking history
export const getBookingHistory = () => async (dispatch) => {

    const API = axios.create({
        baseURL: "https://www.seagoldseawalk.com",
    });

    try {
        const res = await API.get("https://www.seagoldseawalk.com/user_webservice/Login/bookingHistory").then((res) => {
            console.log(res)
            if (res?.data) {
                dispatch({
                    type: GET_BOOKING_HISTORY,
                    payload: res.data
                })
                dispatch({
                    type: SET_FORM_DATA,
                    payload: {}
                })
            }
        });
    } catch (err) {

        if (!err?.response) {
            console.log("no server response");
        }

        if (err.response.data.error_code == 0) {
            dispatch({
                type: BOOKING_HISTORY_ERROR,
                payload: { msg: err.response.data.msg, status: err.response.status }
            })
        }
    }
}

export const setPdfUrl = (url) => async (dispatch) => {
    console.log(url)
    const API = axios.create({
        baseURL: "https://www.seagoldseawalk.com",
    });

    try {
        const res = await API.post("https://www.seagoldseawalk.com/user_webservice/Login/generatePDF", { url }).then((res) => {
            console.log(res);

        });
    } catch (err) {

        if (!err?.response) {
            console.log("no server response");
        }

        if (err.response.data.error_code == 0) {
            dispatch({
                type: BOOKING_FAIL,
                payload: { msg: err.response.data.msg, status: err.response.status }
            })
            console.log("Booking failed", err.response.data.msg); // set alert msg
        }
    }
};