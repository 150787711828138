import { Input, InputNumber, message } from 'antd';
import PropTypes from "prop-types";
import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import Navbar from '../components/layout/Navbar';
import { register } from './../actions/auth';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from 'react-helmet-async'

const Register = ({ register, isAuthenticated }) => {

    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();

    const [userDetails, setUserDetails] = useState({
        name: "",
        email: "",
        password: "",
        password2: "",
        phoneNumber: "",
        city: "",
        state: "",
        country: "",
        userType: "user",
        agencyName: "",
        agencyGSTnumber: "",
        status: "user",
    });

    const [errors, setErrors] = useState({
        name: "",
        email: "",
        password: "",
        password2: "",
        phoneNumber: "",
        mismatchPassword: "",
        city: ""
    });


    // handler for user inputs
    const handleUserInputChange = (event) => {
        setUserDetails({ ...userDetails, [event.target.name]: event.target.value, });
    };

    // handler for user phone number input
    const handleUserPhoneNumberInput = (value) => {
        setUserDetails({ ...userDetails, phoneNumber: value, });
    }


    // handle onsubmit 
    const onSubmit = (e) => {
        e.preventDefault();

        let newErrors = {};

        if (!userDetails.name) {
            newErrors.name = "Name is required";
        }
        if (!userDetails.email) {
            newErrors.email = "Email ID is required";
        }
        if (!userDetails.password) {
            newErrors.password = "Password is required";
        }
        if (!userDetails.password2) {
            newErrors.password2 = "Confirm Password is required";
        }
        if (!userDetails.phoneNumber) {
            newErrors.phoneNumber = "Phone Number is required";
        }
        if (userDetails.password !== userDetails.password2) {
            newErrors.mismatchPassword = "Please enter the valid password";
        }
        if (!userDetails.city) {
            newErrors.city = "City is required";
        }

        // Set all the errors at once
        setErrors(newErrors);

        const areErrorsEmpty = Object.values(newErrors).every((error) => !error);
        // const areUserDetailsValid = Object.values(userDetails).every((value) => value !== null && value !== "");

        if (areErrorsEmpty) {
            console.log("User Details:", userDetails);
            const { name, email, password, password2, phoneNumber, city, userType, status } = userDetails;
            register({ name, email, password, phoneNumber, city, userType, status });

            setUserDetails({
                name: "",
                email: "",
                password: "",
                password2: "",
                phoneNumber: "",
                city: "",
                state: "",
                country: "",
                userType: "user",
                agencyName: "",
                agencyGSTnumber: "",
                status: "user",
            })
        } else {
            // Set all the errors at once
            setErrors(newErrors);
        }
    }


    if (isAuthenticated) {
        return <Navigate to='/login' />
    }

    return (
        <Fragment>
            <Navbar />
            <Helmet>
                <title>Register</title>
                <meta name="description" content="This is what you want to show as the page content in the Google SERP Listing" />
                <link rel='canonical' href='/register'></link>
            </Helmet>
            <div id='login'>
                <LazyLoadImage className='login-bg' src='/images/login/sunset-beach.png' alt='sunset-beach-background'></LazyLoadImage>
                <div className='register-form'>
                    <form onSubmit={(e) => onSubmit(e)}>
                        <h1 className='heading text-center'>Create Account</h1>
                        <div className='form-input p-1'>
                            <label>Name: <span style={{ color: "red" }}>*</span></label>
                            <Input name='name' placeholder="Name" autoComplete="none" type='text' value={userDetails.name} onChange={(e) => handleUserInputChange(e)} />
                            {errors.name && <p className="error">* {errors.name}</p>}
                        </div>
                        <div className='form-input p-1'>
                            <label>Email ID: <span style={{ color: "red" }}>*</span></label>
                            <Input name='email' placeholder="Email Id" autoComplete="none" type='email' value={userDetails.email} onChange={(e) => handleUserInputChange(e)} />
                            {errors.email && <p className="error">* {errors.email}</p>}
                        </div>
                        <div className='form-input p-1'>
                            <label>Phone Number: <span style={{ color: "red" }}>*</span></label>
                            <InputNumber name='phoneNumber' min={1} style={{ width: "100%" }} type='number' placeholder="Phone Number" autoComplete="none" value={userDetails.phoneNumber} onChange={(value) => handleUserPhoneNumberInput(value)} />
                            {errors.phoneNumber && <p className="error">* {errors.phoneNumber}</p>}
                        </div>
                        <div className='form-input p-1'>
                            <label>City: <span style={{ color: "red" }}>*</span></label>
                            <Input name='city' placeholder="City" autoComplete="none" type='text' value={userDetails.city} onChange={(e) => handleUserInputChange(e)} />
                            {errors.city && <p className="error">* {errors.city}</p>}
                        </div>
                        <div className='form-input p-1'>
                            <label>Password: <span style={{ color: "red" }}>*</span></label>
                            <Input name='password' placeholder="Password" autoComplete="none" type='password' value={userDetails.password} onChange={(e) => handleUserInputChange(e)} />
                            {errors.password && <p className="error">* {errors.password}</p>}
                        </div>
                        <div className='form-input p-1'>
                            <label>Confirm Password: <span style={{ color: "red" }}>*</span></label>
                            <Input name='password2' placeholder="Confirm Password" autoComplete="none" type='password' value={userDetails.password2} onChange={(e) => handleUserInputChange(e)} />
                            {errors.password2 && <p className="error">* {errors.password2}</p>}
                            {!errors.password2 && errors.mismatchPassword && <p className="error">* {errors.mismatchPassword}</p>}
                        </div>
                        <div className='form-input'>
                            <button className='form-btn'>Register</button>
                        </div>
                        <div className='form-input'>
                            <p className='form-text'>Already have an account ? <Link to="/login" style={{ color: "#05BFDB" }}>Sign In</Link></p>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    )
}


Register.prototype = {
    register: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps, { register })(Register)

// formatter = {(value) => value.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2-$3')} parser = {(value) => value.replace(/\D/g, '')}