import React from 'react';

const VideoPlayer = () => {
    return (
        <div style={{ maxWidth: '100%', overflow: 'hidden', maxHeight: "100%", height: "100%", width: "100%" }}>
            {/* <video
                autoPlay
                muted
                loop
                style={{
                    width: '100%',
                    height: 'auto',
                    display: 'block',
                }}
            >
                <source src="https://youtu.be/UqAsg81YHio?si=SmQdiMZyNhikm6p7" type="video/mp4" />
                Your browser does not support the video tag.
            </video> */}
            <iframe
                title="YouTube Video"
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/UqAsg81YHio"
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
            />
        </div>
    );
};

export default VideoPlayer;

