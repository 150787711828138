import { AGENT_LOADED, AGENT_ERROR, OTP_VERIFIED, OTP_FAILED, AGREEMENT_ACCEPTED, AGREEMENT_NOTACCEPTED } from "../actions/types";

const initialState = {
    agentDetails: null,
    loading: true,
    error: {}
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case AGENT_LOADED:
            return {
                ...state,
                agentDetails: payload,
                loading: false,
            }
        case AGENT_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        case OTP_VERIFIED:
        case AGREEMENT_ACCEPTED:
            return {
                ...state,
                agentDetails: payload,
                loading: false,
            }
        case OTP_FAILED:
        case AGREEMENT_NOTACCEPTED:
            return {
                ...state,
                loading: false,
            }
        default:
            return state;
    }
}