import React from 'react'

const AgreementContent = () => {
    return (
        <div>
            <h3>The terms and conditions cover the following: (TRAVEL AGENT)</h3>

            <h5>TERMS OF USE & SERVICE</h5>
            <p>
                This web site (the "site") is published and maintained by seagoldseawalk.com (a unit of Khizar Ocean Logistics Pvt. Ltd) a company incorporated and existing in accordance with the laws of the Republic of India. When you access, browse or use this site you accept, without limitation or qualification, the terms and conditions set forth below. When you access any sub-site (whether belonging to an ‘associate’ of seagoldseawalk.com or otherwise) through this site, then such sub-site may have its own terms and conditions of use which is specific to such sub-site. Sub-sites may contain such additional terms and conditions of use as may be set out in such sub-site.
                These terms and conditions of use and any additional terms posted on this site together constitute the entire agreement between seagoldseawalk.com and you with respect to your use of this site.
            </p>

            <h5>SITE AND ITS CONTENTS</h5>
            <p>
                <br></br>i.	This site is only for your personal use. You shall not distribute exchange, modify, sell or transmit anything you copy from this site, including but not limited to any text, images, audio and video, for any business, commercial or public purpose.
                <br></br>ii.	As long as you comply with the terms of these terms and conditions of use, seagoldseawalk.com grants you a non-exclusive, non-transferable, limited right to enter, view and use this site. You agree not to interrupt or attempt to interrupt the operation of this site in any way.
                <br></br>iii.	Access to certain areas of the site may only be available to registered members. To become a registered member, you may be required to answer certain questions. Answers to such questions may be mandatory and/or optional. You represent and warrant that all information you supply to us, about yourself, and others, is true and accurate.
                <br></br>iv.	You warrant that you are at least 18 years of age and possess the legal authority to enter into this agreement and to use this website in accordance with all terms and conditions herein. You agree to supervise all usage by minors of this website under your name or account. You also warrant that all information supplied by you or members of your household in using this website is true and accurate. Without limitation, any speculative, false or fraudulent reservation or any reservation in anticipation of demand is prohibited. You agree that the products and services of this website shall be used only to make legitimate reservations or purchases for you or for another person for whom you are legally authorized to act. You understand that abuse of the products or services of this website may result in you being denied access to this website. You shall be completely responsible for payment/ reimbursement of all charges, fees, duties, taxes (including service tax), and assessments arising out of the use of this website.
            </p>

            <h5>OWNERSHIP</h5>
            <p>
                <br></br>i.	All materials on this site, including but not limited to audio, images, software, text, icons and such like (the "content"), are protected by copyright under international conventions and copyright laws. You cannot use the content, except as specified herein. You agree to follow all instructions on this site limiting the way you may use the content.
                <br></br>ii.	There are a number of proprietary logos, service marks and trademarks found on this site whether owned/used by seagoldseawalk.com or otherwise. By displaying them on this site, seagoldseawalk.com is not granting you any license to utilize those proprietary logos, service marks, or trademarks. Any unauthorized use of the content may violate copyright laws, trademark laws, the laws of privacy and publicity, and civil and criminal statutes.
                <br></br>iii.	You may download such copy/copies of the content to be used only by you for your personal use at home unless the sub site you are accessing states that you may not. If you download any content from this site, you shall not remove any copyright or trademark notices or other notices that go with it.
            </p>

            <h5>OTHERS RIGHTS</h5>
            <p>
                <br></br>If this site contains bulletin boards, chat rooms, access to mailing lists or other message or communication facilities, you agree to use the same only to send and receive messages and materials that are proper and related thereto. By way of example and not as a limitation, you agree that when using the site or any facility available here from, you shall not do any of the following:
                <br></br>i.	If this site contains bulletin boards, chat rooms, access to mailing lists or other message or communication facilities, you agree to use the same only to send and receive messages and materials that are proper and related thereto. By way of example and not as a limitation, you agree that when using the site or any facility available here from, you shall not do any of the following: defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as rights of privacy and publicity) of others publish, post, distribute or disseminate any defamatory, infringing, obscene, indecent or unlawful material or information upload or attach files that contain software or other material protected by intellectual property laws (or by rights of privacy and publicity) unless the user owns or controls the rights thereto or has received all consents therefore as may be required by law upload or attach files that contain viruses, corrupted files or any other similar software or programs that may damage the operation of another’s computer delete any author attributions, legal notices or proprietary designations or labels in any file that is uploaded falsify the origin or source of software or other material contained in a file that is uploaded advertise or offer to sell any goods or services, or conduct or forward surveys, contests or chain letters, or download any file posted by another user of a forum that the user knows, or reasonably should know, cannot be legally distributed in such manner.
            </p>

            <h5>USER'S MATERIAL</h5>
            <p>
                <br></br>i.	You are prohibited from posting or transmitting any defamatory, libelous, obscene, pornographic, profane, threatening or unlawful material or any material that could constitute or encourage conduct that would be considered a criminal offense or give rise to civil liability, or otherwise violate any law.
                <br></br>ii.	seagoldseawalk.com assumes no liability or responsibility arising from the contents of any communications containing any defamatory, erroneous, inaccurate, libelous, obscene or profane material. seagoldseawalk.com may change, edit, or remove any user material or conversations that are illegal, indecent, obscene or offensive, or that violates seagoldseawalk.com policies in any way.
                <br></br>iii.	seagoldseawalk.com will fully cooperate with any law enforcement authorities or court order requesting or directing seagoldseawalk.com to disclose the identity of anyone posting such materials.
            </p>

            <h5>SEAGOLDSEAWALK.COM RIGHTS</h5>
            <p>
                <br></br>i.	If you send any communications or materials to the site by electronic mail or otherwise, including any comments, data, questions, suggestions or the like, all such communications are, and will be treated by seagoldseawalk.com, as non-confidential.
                <br></br>ii.	You hereby give up any and all claim that any use of such material violates any of your rights including moral rights, privacy rights, proprietary or other property rights, publicity rights, rights to credit for material or ideas, or any other right, including the right to approve the way seagoldseawalk.com uses such material.
                <br></br>iii.	Any material submitted to this site may be adapted, broadcast, changed, copied, disclosed, licensed, performed, posted, published, sold, transmitted or used by seagoldseawalk.com anywhere in the world, in any medium, forever.
                <br></br>iv.	Transmitted material
                <br></br>v.	Internet transmissions are never completely private or secure. You understand that any message or information you send to this site may be read or intercepted by others unless there is a special notice that a particular message (for example, credit card information) is encrypted (send in code). Sending a message to seagoldseawalk.com does not cause seagoldseawalk.com to have any special responsibility to you.
                <br></br>vi.	The copyright in the contents of this website belong to seagoldseawalk.com. Accordingly, seagoldseawalk.com reserves all rights. Copying of part or all the contents of this website without permission of seagoldseawalk.com is prohibited except to the extent that such copying/printing is necessary for the purposes of availing of the paid services provided.
            </p>

            <h5>ELIGIBILITY</h5>
            <p>
                Registration as an agent will be provided to agents who request to be registered as an agent through the website after paying any applicable fee. Only agents, equipped with adequate infrastructure, expertise, and the necessary licenses required by applicable law, shall be permitted to register and operate as an agent. seagoldseawalk.com reserves the right to grant registration or otherwise cancel registration granted to an agent at its sole discretion.
            </p>

            <h5>AGENCY TERMS AND CONDITIONS</h5>
            <p>
                <br></br>i.	All bookings, cancellation, refunds, charges etc. Of the services shall be governed by the terms and conditions of the respective third party supplier (such as the airline/hotel/bus operator/supplier including the payment gateway service providers) ("suppliers"). Further, any refund amount to be processed, the period within which such refund is to be made and all related actions shall be as per the terms of the relevant supplier. seagoldseawalk.com shall not, in anyway, be responsible for processing any refunds.
                <br></br>ii.	In the event any reservation using the services does not get confirmed for any reason whatsoever such as technical faults, downtime, etc., seagoldseawalk.com shall forthwith notify the agent about the same. However, seagoldseawalk.com shall not be under any obligation to make another booking in lieu of or to compensate/replace the unconfirmed reservation. All subsequent bookings shall be treated as new transactions without any reference to the earlier unconfirmed reservation.
                <br></br>iii.	Prices of the services provided by each supplier may change during the course of making a booking/reservation using the services. Such change/variation is solely at the discretion of the supplier and seagoldseawalk.com shall not be liable for any such change or variation in the price of products and services offered by such suppliers.
                <br></br>iv.	seagoldseawalk.com may, at any time and without having to serve any prior notice to the agent, (i) upgrade, update, change, modify, or improve the services or a part of the services in a manner it may deem fit, (ii) change any promotion scheme, promotion period, grace period (by whatever name it is called) and (iii) change the contents of the agreement. It is the agent's responsibility, in such cases, to review the terms of the agreement from time to time. Such changes shall be made applicable when they are posted. seagoldseawalk.com may also alter or remove any content from its website without notice and without liability.
                <br></br>v.	Refund/reissuance charges would be as per the norms plus rs.500/ - per person/per trip as charges.
                <br></br>vi.	Service charges levied are to be collected from the customers on our behalf.
                <br></br>vii.	Any violation/cancellation of the ticket will be entertained only till 1800 hrs.
                <br></br>viii.	If the payment is not made on time seagoldseawalk.com can anytime cancel all the tickets without any prior notice.
                <br></br>ix.	Any kind of misuse of tickets/ pnr will solely be the responsibility of the agent and the amount will be recovered from the agent as and when received the debit note by the airline or by the gds.
                <br></br>x.	There will no adjusting of the refund in the payment schedule. The refund will be given back to the agent whenever we have received it from the airline.
                <br></br>xi.	Any advance amount paid by the agent will be non-interest bearing and should be utilized within 360 days from the date of payment. If such advance is not utilized within this period of 360 days, the unutilized portion shall be held in trust, for the benefit of the agent, by seagoldseawalk.com for a period of 2 years from the date of payment of such advance after which the same shall be deemed as forfeited.
                <br></br>xii.	The user shall request seagoldseawalk.com for any refunds against the unutilized or 'no show' booking for any reasons within 90 days from the date of scheduled trip for the ticket and/or the date of check-in for the booking. Any applicable refunds would accordingly be processed as per the defined policies of seagoldseawalk.com as the case may be. No refund would be payable for any requests made after the expiry of 90 days as above and all unclaimed amounts for such unutilized or no show trip booking shall be deemed to have been forfeited.
                <br></br>xiii.	Any other refunds or unadjusted credits received from suppliers (including hotels, airlines etc.) And not claimed or pursued by travel agents or suppliers within 2 years of receipt of such amounts by company, shall be deemed to have been forfeited.
                <br></br>xiv.	Wallet amount can’t be transfer in agent’s bank account. You can utilize it as given period. In case any amount refunded in agent’s bank account then bank will charge refund service charges over and above refunded amount.
            </p>

            <h5>INDEMNITY</h5>
            <p>
                <br></br>You hereby indemnify us and shall continue to keep us indemnified including any of our officers, directors, employees and agents from and against any claims, causes of action, demands, recoveries, losses, damages, fines, penalties or other costs or expenses of any kind or nature including but not limited to reasonable legal and accounting fees, brought by third parties as a result of:
                <br></br>Your breach of these terms of use;
                <br></br>Your violation of any law or the rights of a third party; or
                <br></br>Your use of this website.
            </p>

            <h5>FEES AND PAYMENT</h5>
            <p>
                <br></br>i.	seagoldseawalk.com reserves the right to charge listing fees for certain listings, as well as transaction fees based on certain completed transactions using the services. It further reserves the right to alter any and all fees from time to time, without notice.
                <br></br>ii.	The user shall be completely responsible for all charges, fees, duties, taxes, and assessments arising out of the use of the services.
                <br></br>iii.	In case, there is a short charging by seagoldseawalk.com for listing, services or transaction fee or any other fee or service because of any technical or other reason, it reserves the right to deduct/charge/claim the balance subsequent to the transaction at its own discretion.
            </p>

            <h5>DISCLAIMER</h5>
            <p>
                <br></br>i.	The material in this site could include technical inaccuracies or typographical errors. seagoldseawalk.com may make changes or improvements at any time.
                <br></br>ii.	The materials on this site are provided on an "as is" basis, without warranties of any kind either expressed or implied. To the fullest extent permissible pursuant to applicable law, seagoldseawalk.com disclaims all warranties of merchantability and fitness for a particular purpose.
                <br></br>iii.	seagoldseawalk.com does not warrant that the functions contained in this site will be uninterrupted or error free, that defects will be corrected, or that this site or the servers that make it available are free of viruses or other harmful components, but shall endeavor to ensure your fullest satisfaction.
                <br></br>iv.	seagoldseawalk.com does not warrant or make any representations regarding the use of or the result of the use of the material on the site in terms of their correctness, accuracy, reliability, or otherwise, insofar as such material is derived from other service providers such as airlines, hotel owners and tour operators.
                <br></br>v.	You acknowledge that this website is provided only on the basis set out in these terms and conditions. Your uninterrupted access or use of this website on this basis may be prevented by certain factors outside our reasonable control including, without limitation, the unavailability, inoperability or interruption of the internet or other telecommunications services or as a result of any maintenance or other service work carried out on this website. seagoldseawalk.com does not accept any responsibility and will not be liable for any loss or damage whatsoever arising out of or in connection with any ability/inability to access or to use the site.
                <br></br>vi.	You also acknowledge that through this site, seagoldseawalk.com merely provides intermediary services in order to facilitate highest quality services to you. seagoldseawalk.com is not the last-mile service provider to you and therefore, seagoldseawalk.com shall not be or deemed to be responsible for any lack or deficiency of services provided by any person (airline, travel/tour operator, hotel, facility or similar agency) you shall engage or hire or appoint pursuant to or resulting from, the material available in this site.
                <br></br>vii.	seagoldseawalk.com will not be liable to you or to any other person for any direct, indirect, incidental, punitive or consequential loss, damage, cost or expense of any kind whatsoever and howsoever caused from out of your usage of this site.
                <br></br>viii.	Not with standing anything else to the contrary contained elsewhere herein or otherwise at law, seagoldseawalk.com liability (whether by way of indemnification to you or otherwise) shall be restricted to a maximum of inr 1000 (Indian rupees one thousand only).
            </p>

            <h5>AVAILABILITY</h5>
            <p>
                The products and services displayed on the site may not be available for purchase in your particular country or locality. The reference to such products and services on the site does not imply or warrant that these products or services will be available at any time in your particular geographical location. You should check with your local seagoldseawalk.com authorized representative for the availability of specific products and services in your area.
            </p>

            <h5>TERMS AND CONDITIONS OF USE</h5>
            <p>
                <br></br>i.	seagoldseawalk.com may add to, change or remove any part of these terms and conditions of use at any time, without notice. Any changes to these terms and conditions of use or any terms posted on this site apply as soon as they are posted. By continuing to use this site after any changes are posted, you are indicating your acceptance of those changes.
                <br></br>ii.	seagoldseawalk.com may add, change, discontinue, remove or suspend any other content posted on this site, including features and specifications of products described or depicted on the site, temporarily or permanently, at any time, without notice and without liability.
                <br></br>iii.	seagoldseawalk.com reserves the right to undertake all necessary steps to ensure that the security, safety and integrity of seagoldseawalk.com systems as well as its clients’ interests are and remain, well-protected. Towards this end seagoldseawalk.com may take various steps to verify and confirm the authenticity, enforceability and validity of orders placed by you.
                <br></br>iv.	If seagoldseawalk.com, in its sole and exclusive discretion, concludes that the said orders are not or do not reasonably appear to be, authentic, enforceable or valid, then seagoldseawalk.com may cancel the said orders at any time up to 4 hours before the scheduled time of departure of the relevant flight or 4 hours before the expected date of visit to any property booked through seagoldseawalk.com
            </p>

            <h5>GENERAL PROVISIONS</h5>
            <p>
                <br></br>i.	You may not permitted to trip to certain destinations which involve greater risks than others, entirely at your risk as to cost and consequences.seagoldseawalk.com requests you to consult your local authorities and evaluate travel prohibitions, warning, announcements, and advisories issued by them before booking.
                <br></br>ii.	By offering/sale of tickets for sea walk, seagoldseawalk.com does not represent or warrant that trip to such point is advisable or without risk. seagoldseawalk.com does not accept liability for damages, losses, or delays that may result from improper documents for entry, exit, length of stay, or from travel to such destinations.
                <br></br>iii.	seagoldseawalk.com reserves its exclusive right in its sole discretion to alter, limit or discontinue the site or any material posted herein, in any respect. seagoldseawalk.com shall have no obligation to take the needs of any user into consideration in connection therewith.
                <br></br>iv.	seagoldseawalk.com reserves its right to deny in its sole discretion any user access to this site or any portion hereof without notice.
                <br></br>v.	No waiver by seagoldseawalk.com of any provision of these terms and conditions shall be binding except as set forth in writing and signed by its duly authorized representative.
                <br></br>vi.	If any dispute arises between you and seagoldseawalk.com during your use of the site or thereafter, in connection with and arising from your use or attempt to use this site, the dispute shall be referred to arbitration. The place of arbitration shall be Port Blair. The arbitration proceedings shall be in the English language.
                <br></br>vii.	The said arbitration proceedings shall be governed and construed in accordance with the Arbitration and Conciliation Act, 1996 and modifications thereof as in force at the relevant time.
                <br></br>viii.	These terms and conditions are governed by and shall be construed in accordance with the laws of the Republic of India and any dispute shall exclusively be subject to the jurisdiction of the appropriate Courts situated at Port Blair, A & N Islands.
                <br></br>ix.	These terms and conditions are governed by and shall be construed in accordance with the laws of the Republic of India and any dispute shall exclusively be subject to the jurisdiction of the appropriate courts situated at Port Blair, A & N Islands.
            </p>

            <h5>REFUND / CANCELLATION PROCEDURE</h5>
            <p>
                You can cancel the bookings online from portal. Log in and go to ticket queues – filter by pnr/date of booking – select change request – select option from dropdown – fill clear remarks and sent request. seagoldseawalk.com will charge Rs. 200 per person per trip as standard cancellation service charges over and above the  own cancellation charge.
                Option available in change request drop down which should be select in case of.
                <br></br>•	Refund: normal cancellation as per company policy.
                <br></br>•	Change itinerary /reissuance: if you want to reschedule the ticket.
                <br></br>•	Partial refund: if you want to any alternate trips.
                <br></br>•	Trip cancelled: if trip cancel from company side or reschedule more than 04 hours are eligible for full refund as per company policy.
            </p>

            <h5>REFUND POLICY</h5>
            <p>i.	seagoldseawalk.com will process the refund only after getting cancellation request/claim from the customer/travel agent/corporate company through change request section or email.
                <br></br>ii.	Refund against the cancellation of trip tickets will be credited directly in agent portal/wallet. seagoldseawalk.com will process refund within 72 only after receiving it from the concerned travel agent/hotel/transporter/other suppliers.
                <br></br>iii.	Wallet amount once used for any transaction the remaining amount in the wallet cannot be transferred to your bank account. However, can be used for future booking.
                <br></br>iv.	In case you require in your bank account then we will take 10% refund service charges over and above on refundable amount. In this case refund will be process within 15-20 working days.
                <br></br>v.	The following charges will be levied on any cancellation:
                <br></br>•	30 days or more 100% of Deposit
                <br></br>•	15 – 30 days 50% of Deposit
                <br></br>•	8 – 15 days 80% of trip Cost
                <br></br>•	1 – 7 days 100% of trip Cost</p>
        </div>
    )
}

export default AgreementContent