import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { message } from 'antd';

const Alert = ({ alerts }) => {
    // Extract message API and context holder from message
    const [messageApi, contextHolder] = message.useMessage();

    // Function to handle alert messages
    const handleAlerts = () => {
        // Check if alerts are present
        if (alerts !== null && alerts.length > 0) {
            // Loop through alerts and display corresponding messages
            alerts.forEach((alert) => {
                // Set Ant Design message type based on alert type
                const messageType = alert.alertType === 'error' ? 'error' : 'success';

                // Display the message
                messageApi.open({
                    type: messageType,
                    content: alert.msg,
                    className: `custom-${alert.alertType}-message`,
                });
            });
        }
    };

    // Call handleAlerts function
    alerts.length && handleAlerts();

    // Render the context holder for messages
    return <>{contextHolder}</>;
};

Alert.propTypes = {
    alerts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
    alerts: state.alert,
});

export default connect(mapStateToProps)(Alert);



