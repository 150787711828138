import { Space, Table, Tag } from 'antd';
import React, { Fragment, useEffect } from 'react';
import Navbar from '../../components/layout/Navbar';
import { connect } from 'react-redux';
import { PDFDownloadLink, PDFViewer, usePDF } from '@react-pdf/renderer';
import MyDocument from '../../components/ui/DownloadPDF';
import { Navigate } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from 'react-helmet-async';
import { getBookingHistory } from '../../actions/booking';
import Spinner from '../../components/ui/Spinner';


const BookingHistory = ({ getBookingHistory, booking: { bookingHistory, loading }, auth: { isAuthenticated, user } }) => {

  useEffect(() => {
    getBookingHistory()
  }, [getBookingHistory])

  const options = {
    timeZone: 'Asia/Kolkata', // Set the time zone to IST
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };

  const columns = [
    {
      title: 'Booking ID',
      dataIndex: 'booking_id',
      key: 'booking_id',
      width: 230,
      fixed: 'left',
    },
    {
      title: 'Booked Date',
      dataIndex: 'date_time',
      key: 'date_time',
      width: 250,
      render: (text, bookingDetails) => (
        <p className='m-0'>{new Intl.DateTimeFormat('en-US', options).format(new Date(bookingDetails.date_time))}</p>
      ),
    },
    {
      title: 'Sea Walk Date',
      dataIndex: 'seawalk_date',
      key: 'seawalk_date',
      width: 150,
    },
    {
      title: 'No.Of Travellers',
      dataIndex: 'no_of_tourist',
      key: 'no_of_tourist',
      width: 110,
      render: (text, bookingDetails) => (
        <p className='m-0'>{bookingDetails.tourist_details.length}</p>
      ),
    },
    {
      title: 'Payment Status',
      key: 'status',
      dataIndex: 'status',
      width: 150,
      render: (_, { status }) => {
        let color;
        if (status === 'not paid') {
          color = 'volcano';
        }
        if (status === 'paid') {
          color = 'green';
        }
        if (status === 'pending') {
          color = 'geekblue';
        }
        return (
          <Tag color={color} key={status}>
            {status.toUpperCase()}
          </Tag>
        )
      }
    },
    {
      title: 'PDF',
      key: 'pdf',
      render: (_, bookingDetails) => (
        <Space size="middle">
          <PDFDownloadLink className="pt-3 history download-bottom-btn" document={<MyDocument id={bookingDetails.id} bookingDetails={bookingDetails} user={user} />} fileName={`SeaWalk in Andaman Ticket.pdf`}>
            {({ blob, url, loading, error }) =>
              loading ? 'Download PDF' : <button>Download PDF <LazyLoadImage style={{ width: "17px" }} className='ms-2' src='/images/thankyou/download-white.png' alt='download'></LazyLoadImage></button>
            }
          </PDFDownloadLink>
        </Space>
      ),
    },
  ];

  !isAuthenticated && <Navigate to="/" />

  return loading || bookingHistory == null ? <Spinner /> : (
    <Fragment>
      <Navbar />
      <Helmet>
        <title>Booking History</title>
        <meta name="description" content="This is what you want to show as the page content in the Google SERP Listing" />
        <link rel='canonical' href='/booking-history'></link>
      </Helmet>
      <section id='home_banner'>
        <LazyLoadImage className='home_banner-img' src='/images/contact/update-profile.jpg' alt='andaman nicobar islands'></LazyLoadImage>
        <h1 className='home-main-title'>ANDAMAN & NICOBAR ISLANDS</h1>
        <p className='home-main-sub-title'>(Biggest Seawalk in India - Sea Gold Sea Walk)</p>
      </section>
      <div className='booking-history'>
        <h1 className='heading text-center'>Booking History</h1>
        <p className='text-center'> You can view & manage your complete list of Booking details </p>

        <br></br>
        <div className='booking-history-table'>
          <Table
            columns={columns}
            dataSource={bookingHistory}
            scroll={{ x: 1300 }}
          />
        </div>
      </div>
    </Fragment>
  )
}

BookingHistory.prototype = {
  getBookingHistory: PropTypes.func.isRequired,
  booking: PropTypes.array.isRequired,
  auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  return {
    booking: state.booking,
    auth: state.auth
  }
}

export default connect(mapStateToProps, { getBookingHistory })(BookingHistory);