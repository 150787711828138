import React, { useEffect } from 'react'
import MyDocument from '../../components/ui/DownloadPDF'
import { connect } from 'react-redux';
import { loadPaymentData } from './../../actions/booking';
import { Navigate, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';

const ViewPDF = ({ loadPaymentData, booking: { bookingDetails } }) => {
    const id = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        loadPaymentData(id.viewPDF);
    }, [loadPaymentData])

    return (
        <div className='d-flex flex-column justify-content-center align-items-center' style={{ width: '100%', height: '100vh' }}>
            <PDFDownloadLink className="pt-3 pb-3 download-bottom-btn" document={<MyDocument bookingDetails={bookingDetails} />} fileName={bookingDetails?.booking_id}>
                {({ blob, url, loading, error }) =>
                    loading ? 'Download PDF' : <button className='ms-auto me-auto'>Download PDF <img style={{ width: "25px" }} className='ms-2' src='/images/thankyou/download-white.png' alt='download'></img></button>
                }
            </PDFDownloadLink>
            {
                bookingDetails && <PDFViewer style={{ width: '100%', height: '100%' }}>
                    <MyDocument bookingDetails={bookingDetails} />
                </PDFViewer>
            }

        </div>
    )
}

ViewPDF.prototype = {
    loadPaymentData: PropTypes.func.isRequired,
    booking: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
    booking: state.booking
})

export default connect(mapStateToProps, { loadPaymentData })(ViewPDF)