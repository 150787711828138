import { PDFDownloadLink, PDFViewer, usePDF } from '@react-pdf/renderer';
import React, { Fragment, useState, useEffect } from 'react';
import MyDocument from '../components/ui/DownloadPDF';
import "./ThankYou.scss";
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { loadPaymentData } from './../actions/booking';
import { PropTypes } from 'prop-types';


const BookingFailure = ({ loadPaymentData, auth: { isAuthenticated, user } }) => {

    const id = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        loadPaymentData(id.bookingFailed);
    }, [loadPaymentData])

    const options = {
        timeZone: 'Asia/Kolkata', // Set the time zone to IST
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    };


    const goBookingHistory = () => {
        localStorage.removeItem("booking details");
        navigate('/')
    }

    return (
        <Fragment>
            <div id='thank-you' className='h-100'>
                <LazyLoadImage className='thank-you-bg' src='/images/thankyou/sea-bg.jpg' alt='sea-background'></LazyLoadImage>
                <div id='booking-success'>
                    <h1 className='booking-success-text text-center p-0'><LazyLoadImage className='success-img' src='/images/thankyou/failure.png' alt='successfully-booked'></LazyLoadImage>Oops! Payment Failed!</h1>
                </div>
                {/* <p className='text-center mb-3 m-sm-0' style={{ color: "red" }}>Your booking for {formdata.travelers} {formdata.travelers > 1 ? "persons" : "person"} on {(new Date(formdata.seawalkdate).toDateString())} has been failed.</p> */}
                <p className='text-center mb-3 m-sm-0'>Please try again</p>
                <br></br>
                <div className='d-flex justify-content-center align-items-center'>
                    <button className="customer-support" onClick={goBookingHistory}>Go To Home Page</button>
                </div>
            </div>
        </Fragment>
    )
}

BookingFailure.prototype = {
    auth: PropTypes.object.isRequired,
    loadPaymentData: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    auth: state.auth
})

export default connect(mapStateToProps, { loadPaymentData })(BookingFailure)