import React, { Fragment, useState } from 'react';
import { Footer } from '../components/layout/Footer';
import Navbar from '../components/layout/Navbar';
import "./About.scss";
import "./Home.scss";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from 'react-helmet-async'
import VideoPlayer from '../components/ui/VideoPlayer';
import { FloatButton } from 'antd';
import { Button, Modal } from 'antd';
import { CapModalSlider, FoodModalSlider } from '../components/ui/SwiperSlider';

export const About = () => {

    const [isFoodModalOpen, setIsFoodModalOpen] = useState(false);
    const [isCapModalOpen, setIsCapModalOpen] = useState(false);

    return (
        <Fragment>
            <Navbar />
            <Helmet>
                <title>About</title>
                <meta name="description" content="This is what you want to show as the page content in the Google SERP Listing" />
                <link rel='canonical' href='/about'></link>
            </Helmet>
            <div className='home'>
                <section id='home_banner'>
                    <LazyLoadImage className='home_banner-img' src='/images/about/andaman-sea-walk-about-bg.png' alt='andaman nicobar islands'></LazyLoadImage>
                    <h1 className='home-main-title'>ANDAMAN & NICOBAR ISLANDS</h1>
                    <p className='home-main-sub-title'>(Largest Seawalk in India - Sea Gold Sea Walk)</p>
                </section>
                <section id='about-section' className='pb-0'>
                    <h1 className='heading text-center mb-4'>About Us</h1>
                    <p className='text-center'>Welcome to Sea Gold Sea Walk, an extraordinary venture by Khizar Ocean Logistics Private Limited, based in the breathtaking Andaman Islands. Our journey began in 2023, driven by the passion to create memorable and immersive experiences for travelers seeking the beauty of the ocean depths.</p>
                </section>
                <section id='mission-vision'>
                    <div className='row m-0'>
                        <div className='col-md-6 p-2'>
                            <div className='mission-vision-card'>
                                <h3>Our Mission</h3>
                                <p>To pioneer unparalleled sea walking adventures, ensuring the safety and satisfaction of our guests. Through cutting-edge technology, environmental stewardship, and community engagement, we strive to elevate the standards of marine tourism. At Sea Gold Sea Walk, every step is a journey of discovery and every moment is crafted to leave a lasting impression.</p>
                            </div>
                        </div>
                        <div className='col-md-6 p-2'>
                            <div className='mission-vision-card'>
                                <h3>Our Vision</h3>
                                <p>At Sea Gold Sea Walk, we envision becoming a beacon of adventure, connecting people with the wonders of the underwater world. Our vision is to be the premier destination for sea walking adventures, setting the standard for safety, innovation, and environmental responsibility in marine tourism.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section id='video-section'>
                    {/* <img className='wave' src='/images/home/wave1.jpg' alt="sea walk in andaman"></img> */}
                    <h1 className="heading text-center pb-5">
                        SeaWalk Walkthrough Video
                    </h1>
                    <div id='video-player'>
                        <VideoPlayer />
                    </div>
                </section>
                <section id='about-section' className='pb-0 pt-3'>
                    <h1 className='details_title'>What is Sea Walking?</h1>
                    <p>Sea walking is a unique and exhilarating underwater experience that allows you to explore the vibrant marine life without the need for diving certifications or heavy equipment. Imagine strolling on the ocean floor surrounded by schools of colorful fish, vibrant coral reefs, and the mesmerizing dance of marine life.</p>
                    <br></br>
                    <h1 className='details_title'>How Does it Work?</h1>
                    <p>Our state-of-the-art sea walking helmets provide a clear, panoramic view of the underwater world while keeping you comfortably dry. You'll descend into the azure depths while connected to our expert guides, ensuring your safety and making the experience accessible to all adventure enthusiasts.</p>
                    <br></br>
                    <h1 className='details_title'>Why Sea Gold Sea Walk?</h1>
                    <ul className='p-0'>
                        <li><strong className='details_subTitle'>Safety First:<br></br>  </strong> Our commitment to safety is unwavering. We adhere to the highest international safety standards, ensuring a secure and enjoyable sea walking adventure.</li>
                        <li><strong className='details_subTitle'>Environmental Stewardship:<br></br>  </strong> We understand the importance of preserving our oceans. Sea Gold Sea Walk is dedicated to responsible tourism practices and marine conservation.</li>
                        <li><strong className='details_subTitle'>Unforgettable Memories:<br></br>  </strong> Your satisfaction is our priority. Sea Gold Sea Walk is designed to make every sea walking experience memorable, combining adventure and serenity seamlessly.</li>
                    </ul>
                    <br></br>
                    <h1 className='details_title'>Join Us in the Depths</h1>
                    <p>Embark on an unforgettable journey beneath the waves with Sea Gold Sea Walk. Whether you're a seasoned traveler or a first-time adventurer, our sea walking experience promises to be a highlight of your Andaman Islands visit.</p>
                    <br></br>
                </section>
                <section id='about-team'>
                    <h1 className='heading text-center'>Our Team</h1>
                    <div className='pt-5 row m-0'>
                        <div className='col-sm-12 p-2 d-flex justify-content-center align-items-center'>
                            <div className='about-team-card row m-0'>
                                <div className='about-team-card-img col-sm-4 col-md-5 p-3'>
                                    <img src='/images/about/E K Arif Ali.jpeg' alt='team'></img>
                                </div>
                                <div className='p-3 col-sm-8 col-md-7 d-flex flex-column justify-content-center align-items-center'>
                                    <h4 className='text-center m-0 fw-bold'>Mr. E K Arif Ali</h4>
                                    <p className='text-center m-0'><b>Founder</b></p>
                                    <p className='text-center m-0'> Being one of the partner in Khizar Ocean Wave, he is a person of well known to the business industry more particularly in the tourism sector having vast experience over two decades in the best known hospitality industry in Port Blair. Apart from this, a person known for his social activities and in political arena with a kind hearted person of easy approachable and amicable qualities makes his presence to be considered a definite pillar in Khizar Ocean Wave.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 p-2 d-flex justify-content-center align-items-center'>
                            <div className='about-team-card row m-0'>
                                <div className='about-team-card-img col-sm-4 col-md-5 p-3'>
                                    <img src='/images/about/Mohammed Huzaifa.jpeg' alt='team'></img>
                                </div>
                                <div className='p-3 col-sm-8 col-md-7 d-flex flex-column justify-content-center align-items-center'>
                                    <h4 className='text-center m-0 fw-bold'>Mr. Mohammed Huzaifa</h4>
                                    <p className='text-center m-0'><b>Co Founder</b></p>
                                    <p className='text-center m-0'>Being another partner in Khizar Ocean Wave, is a person well known for his energetic and enthusiastic approach in build the company in commendable. Having good hand experience since from the year 2006 in Shipping, Tourism and relegated industry, his presence as a partner is utmost significance in brining the dream of Khizar Ocean Wave into a reality.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section id='about-section'>
                    <h1 className='heading text-center'>Why Choose Us?</h1>
                    <div className='pt-5 row'>
                        <div className='col-md-3 p-2'>
                            <AboutCard title="Expert Guides" description="Our team of experienced divers and marine enthusiasts are dedicated to ensuring your safety while providing insightful information about the marine ecosystem." src="/images/about/Expert Guides.png"/>
                        </div>
                        <div className='col-md-3 p-2'>
                            <AboutCard title="Unique Experience" description="Sea walking is an extraordinary way to explore the underwater realm without the need for extensive diving training. It's an experience suitable for all ages and skill levels."  src="/images/about/Unique Experience.png" />
                        </div>
                        <div className='col-md-3 p-2'>
                            <AboutCard title="Conservation Advocates" description="We believe in giving back to the ocean that gives us so much. A portion of our proceeds goes towards local marine conservation efforts."  src="/images/about/Conservation Advocates.png" />
                        </div>
                        <div className='col-md-3 p-2'>
                            <AboutCard title="Safety First" description="Your safety is our top priority. We adhere to strict safety standards, provide thorough instructions, and maintain state-of-the-art equipment."  src="/images/about/Safety First.png" />
                        </div>
                    </div>
                </section> */}
                <FloatButton
                    shape="circle"
                    type="primary"
                    style={{ right: 15, bottom: 135 }}
                    onClick={() => setIsFoodModalOpen(true)}
                    icon={<LazyLoadImage src='images/home/food.png' alt='food menu'></LazyLoadImage>}
                    tooltip={<div style={{ color: "white" }}>Food Menu</div>}
                />
                <FloatButton
                    shape="circle"
                    type="primary"
                    style={{ right: 15, bottom: 75 }}
                    onClick={() => setIsCapModalOpen(true)}
                    icon={<LazyLoadImage src='images/home/cap.png' alt='food menu'></LazyLoadImage>}
                    tooltip={<div style={{ color: "white" }}>Free Cap</div>}
                />
                <Modal title="Food Menu" open={isFoodModalOpen} onOk={() => setIsFoodModalOpen(false)} onCancel={() => setIsFoodModalOpen(false)}>
                    <FoodModalSlider />
                </Modal>
                <Modal title="Cap Free" open={isCapModalOpen} onOk={() => setIsCapModalOpen(false)} onCancel={() => setIsCapModalOpen(false)}>
                    <CapModalSlider />
                </Modal>
            </div>
            <Footer />
        </Fragment>
    )
}
