import React, { useState } from "react";
import { Form as AntdForm, Button, Select, Checkbox, Modal, message, Result } from "antd";
import { Form, Input } from "antd";
import { connect } from "react-redux";
import { acceptAgreement } from './../../actions/agent';
import { PropTypes } from 'prop-types';
import AgreementContent from "./AgreementContent";
const { Option } = Select;

const BackBtn = ({ onBack }) => {
    return <Button onClick={() => onBack()}>Back</Button>;
};

const Step2Form = ({ data, onSuccess, onBack, acceptAgreement, agent: { agentDetails } }) => {

    const onFinish = async (values) => {
        // Handle form submission logic here
        await onSuccess(values);

        console.log(values);

        acceptAgreement({ agentId: agentDetails?.id, agentName: values.agentName });
        // Display success message
        message.success("Thanks for accepting the agreement and wait for onboarding details");

    };

    const [open, setOpen] = useState(false);

    return (
        <AntdForm
            name="step2Form"
            initialValues={data}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
        >
            {
                agentDetails?.status === "Agreement Accepted" || agentDetails?.status == 'Verified' ? (
                    <Result
                        status="success"
                        title="Successfully Accepted Agreement"
                        subTitle="Thanks for accepting the agreement and wait for onboarding details"
                    />) : <>
                    <Form.Item label="Enter Name" name="agentName" rules={[{ required: true, message: "Please enter your full name!" }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item>
                        <Button onClick={() => setOpen(true)}>
                            View Agreement
                        </Button>
                        <Modal
                            title="View Agreement"
                            centered
                            open={open}
                            onOk={() => setOpen(false)}
                            onCancel={() => setOpen(false)}
                            width={1000}
                        >
                            <AgreementContent />
                        </Modal>
                    </Form.Item>

                    <Form.Item>
                        <Form.Item
                            name="acceptAgreement"
                            valuePropName="checked"
                            noStyle
                            rules={[{ required: true, message: "Please accept the agreement!" }]}
                        >
                            <Checkbox>
                                I Accept Agreement
                            </Checkbox>
                        </Form.Item>
                    </Form.Item>

                    <Form.Item>
                        <Form.Item
                            name="acceptTerms"
                            valuePropName="checked"
                            noStyle
                            rules={[{ required: true, message: "Please accept the terms and conditions!" }]}
                        >
                            <Checkbox>
                                I Accept Terms & Conditions
                            </Checkbox>
                        </Form.Item>
                    </Form.Item>

                    <Form.Item>
                        {/* <BackBtn onBack={onBack} /> */}
                        &nbsp;
                        <Button type="primary" htmlType="submit">
                            Accept
                        </Button>
                    </Form.Item>

                </>
            }

        </AntdForm>
    );
};

Step2Form.prototype = {
    acceptAgreement: PropTypes.func.isRequired,
    agent: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    agent: state.agent
})

export default connect(mapStateToProps, { acceptAgreement })(Step2Form);
