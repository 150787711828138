import React, { Fragment, useState } from 'react';
import { Footer } from '../components/layout/Footer';
import Navbar from '../components/layout/Navbar';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from 'react-helmet-async';
import { FloatButton } from 'antd';
import { Button, Modal } from 'antd';
import { CapModalSlider, FoodModalSlider } from '../components/ui/SwiperSlider';

function HowToReach() {

    const [isFoodModalOpen, setIsFoodModalOpen] = useState(false);
    const [isCapModalOpen, setIsCapModalOpen] = useState(false);

    return (
        <Fragment>
            <Navbar />
            <Helmet>
                <title>How To Reach</title>
                <meta name="description" content="This is what you want to show as the page content in the Google SERP Listing" />
                <link rel='canonical' href='/how-to-reach'></link>
            </Helmet>
            <div className='home'>
                <section id='home_banner'>
                    <LazyLoadImage className='home_banner-img' src='/images/contact/how-to-reach.png' alt='andaman nicobar islands'></LazyLoadImage>
                    <h1 className='home-main-title'>ANDAMAN & NICOBAR ISLANDS</h1>
                    <p className='home-main-sub-title'>(Largest Seawalk in India - Sea Gold Sea Walk)</p>
                </section>
                <section id='about-section'>
                    <h1 className='heading text-center mb-4'>Welcome to Sea Gold Sea Walk - Your Gateway to Underwater Bliss!</h1>
                    <p className='text-center'>Embark on an extraordinary journey with Sea Gold Sea Walk, where the underwater world of the Andaman Islands awaits your exploration. Our unique sea walking adventures promise an unparalleled blend of thrill and tranquility beneath the azure waves.</p>
                </section>
                <div className="d-flex flex-wrap justify-content-center align-items-center pb-4">
                    <div id="process-card">
                        <div className="process-card_details">
                            <h5 className="text-center">1. India</h5>
                            <p className="text-center">Whether you are from India or anyother of part of world take a flight from India to Port Blair, Andaman Islands.</p>
                        </div>
                    </div>

                    <div id="process-card">
                        <div className="process-card_details">
                            <h5 className="text-center">2. Andaman Nicobar Islands</h5>
                            <p className="text-center">From India, take a flight to Port Blair Airport (IXZ) which is the Capital of beautiful Andaman and Nicobar Islands.</p>
                        </div>
                    </div>

                    <div id="process-card">
                        <div className="process-card_details">
                            <h5 className="text-center">3. Havelock Beach</h5>
                            <p className="text-center"> To reach Elephant Beach, you need to take a Ferry from Port Blair, Andaman Islands. </p>
                        </div>
                    </div>

                    <div id="process-card">
                        <div className="process-card_details">
                            <h5 className="text-center">4. Elephant Beach</h5>
                            <p className="text-center">From this location, you will be guided by Sea Walk master's to adventure the Sea Walk.
                            </p>
                        </div>
                    </div>
                </div>
                <section id='about-section' className='pt-3'>
                    <h1 className='heading text-center mb-4'>Discover Your Path to Sea Gold Sea Walk</h1>
                    <h1 className='details_title'>Dive into the Depths:</h1>
                    <p>As you plan your visit, knowing how to reach us is crucial. Whether you're arriving by air or sea, the Andaman Islands offer multiple gateways to your Sea Gold Sea Walk adventure.</p>
                    <br></br>
                    <h1 className='details_title'>Aerial Odyssey:</h1>
                    <p>Begin your journey by landing at Veer Savarkar International Airport in Port Blair. From there, our team will guide you to the mesmerizing world beneath the waves.</p>
                    <br></br>
                    <h1 className='details_title'>Nautical Ventures:</h1>
                    <p>For a maritime adventure, consider arriving by sea. Passenger ships and ferries from Kolkata, Chennai, and Vizag bring you closer to the Andaman experience. The sea voyage itself is a prelude to the wonders that await you.</p>
                    <br></br>
                    <h1 className='details_title'>Seamless Transportation:</h1>
                    <p>Once you've arrived in Port Blair, our dedicated transportation services will seamlessly take you to the Sea Gold Sea Walk location. Enjoy the scenic ride as you anticipate the unique adventure that lies ahead.</p>
                    <br></br>
                    <h1 className='details_title'>Let Us Guide You:</h1>
                    <p>Our on-site representatives are ready to welcome you and assist throughout your journey. From transportation arrangements to answering your queries, we are here to make your Sea Gold Sea Walk experience unforgettable.</p>
                    <br></br>
                    <h1 className='details_title'>Connect with Us:</h1>
                    <p>Have specific questions or need additional information? Our customer service team is just a message or call away. Reach out to us, and let the excitement of your underwater escapade begin!</p>
                    <br></br>
                    <p>Prepare to step into a world where sea walking isn't just an activity; it's a doorway to a realm of marine marvels. Welcome to Sea Gold Sea Walk!</p>
                </section>
            </div>
            <Footer />
            <FloatButton
                shape="circle"
                type="primary"
                style={{ right: 15, bottom: 135 }}
                onClick={() => setIsFoodModalOpen(true)}
                icon={<LazyLoadImage src='images/home/food.png' alt='food menu'></LazyLoadImage>}
                tooltip={<div style={{ color: "white" }}>Food Menu</div>}
            />
            <FloatButton
                shape="circle"
                type="primary"
                style={{ right: 15, bottom: 75 }}
                onClick={() => setIsCapModalOpen(true)}
                icon={<LazyLoadImage src='images/home/cap.png' alt='food menu'></LazyLoadImage>}
                tooltip={<div style={{ color: "white" }}>Free Cap</div>}
            />
            <Modal title="Food Menu" open={isFoodModalOpen} onOk={() => setIsFoodModalOpen(false)} onCancel={() => setIsFoodModalOpen(false)}>
                <FoodModalSlider />
            </Modal>
            <Modal title="Cap Free" open={isCapModalOpen} onOk={() => setIsCapModalOpen(false)} onCancel={() => setIsCapModalOpen(false)}>
                <CapModalSlider />
            </Modal>
        </Fragment>
    )
}

export default HowToReach