import { v4 as uuidv4 } from 'uuid';
import { AGENT_REQUEST_FAIL, AGENT_REQUEST_SENT, AUTH_ERROR, BOOKING_FAIL, BOOKING_SUCCESS, ENQUIRY_FAIL, ENQUIRY_SENT, GUEST_USER, LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT, PROFILE_UPDATED, PROFILE_UPDATED_FAIL, REGISTER_FAIL, REGISTER_SUCCESS, SET_FORM_DATA, USER_LOADED } from "./types";
import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';
import { setAlert } from './alert';

// load user 
export const loadUser = () => async (dispatch) => {

    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    const API = axios.create({
        baseURL: "https://www.seagoldseawalk.com",
    });

    try {
        const res = await API.get("https://www.seagoldseawalk.com/user_webservice/Login/loadUser").then((res) => {
            console.log(res)
            if (res?.data) {
                dispatch({
                    type: USER_LOADED,
                    payload: res?.data
                })
                console.log("user loaded");
            }
        });
    } catch (err) {

        if (!err?.response) {
            console.log("no server response");
        }

        if (err.response.data.error_code == 0) {
            dispatch({
                type: AUTH_ERROR,
            })
            console.log("error", err.response.data.msg); // set alert msg
        }
    }
}

// register user
export const register = ({ name, email, password, phoneNumber, city, userType, status }) => async (dispatch) => {
    const API = axios.create({
        baseURL: "https://www.seagoldseawalk.com",
    });

    try {
        const res = await API.post("https://www.seagoldseawalk.com/user_webservice/Login/register", {
            name, email, phoneNumber, city, password, userType, status
        }).then((res) => {
            console.log(res.data)
            if (res?.data.error_code == 1) {
                dispatch({
                    type: REGISTER_SUCCESS,
                })
                window.location.href = '/login';
                dispatch(setAlert("Registered successfully", "success"))
            }
            dispatch(loadUser());
        });
    } catch (err) {

        if (!err?.response) {
            console.log("no server response");
        }

        if (err.response.data.error_code == 0) {
            dispatch({
                type: REGISTER_FAIL,
            })
            err.response.data.msg && dispatch(setAlert(err.response.data.msg, "error"))
        }
    }

}

// update user profile
export const updateUserProfile = ({ name, city, state, country }) => async (dispatch) => {
    const API = axios.create({
        baseURL: "https://www.seagoldseawalk.com",
    });

    try {
        const res = await API.post("https://www.seagoldseawalk.com/user_webservice/Login/updateUser", {
            name, city, state, country
        }).then((res) => {

            if (res?.data.error_code == 1) {
                dispatch({
                    type: PROFILE_UPDATED,
                })
                dispatch(setAlert("Profile Updated successfully", "success"))
                dispatch(loadUser())
            }
        });
    } catch (err) {

        if (!err?.response) {
            console.log("no server response");
        }

        if (err.response.data.error_code == 0) {
            dispatch({
                type: PROFILE_UPDATED_FAIL,
            })
            err.response.data.msg && dispatch(setAlert(err.response.data.msg, "error"))
        }
    }

}

// login user
export const login = ({ email, password }) => async (dispatch) => {

    const API = axios.create({
        baseURL: "https://www.seagoldseawalk.com",
    });

    try {
        const res = await API.post("https://www.seagoldseawalk.com/user_webservice/Login/login", {
            email, password
        }).then((res) => {
            console.log(res)
            if (res?.data.token) {
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: res?.data
                })
                dispatch(loadUser())
                dispatch(setAlert("Welcome, you have Logged in Successfully!", "success"))
            }
        });
    } catch (err) {

        if (!err?.response) {
            console.log("no server response");
        }

        if (err.response.data.error_code == 0) {
            dispatch({
                type: LOGIN_FAIL,
            })
            err.response.data.msg && dispatch(setAlert(err.response.data.msg, "error"))
            // console.log("login failed", err.response.data.msg); // set alert msg
        }
    }

}

// logout / clear profile
export const logout = () => (dispatch) => {
    dispatch({ type: LOGOUT })
    dispatch(setAlert("Logout done", "success"))
}

// register Agent
export const agentRequest = (agencyRequestDetails) => async (dispatch) => {

    const { name, email, phoneNumber, agencyName, agencyGSTnumber, city, state, country } = agencyRequestDetails;

    const API = axios.create({
        baseURL: "https://www.seagoldseawalk.com",
    });

    try {
        const res = await API.post("https://www.seagoldseawalk.com/user_webservice/Login/agentRequest", {
            ...agencyRequestDetails, status: "Registered"
        }).then((res) => {

            if (res?.data.error_code == 1) {
                dispatch({
                    type: AGENT_REQUEST_SENT,
                })
                dispatch(setAlert("Agent Request Sent successfully", "success"))
            }
            dispatch(loadUser())
        });
    } catch (err) {

        if (!err?.response) {
            console.log("no server response");
        }

        if (err.response.data.error_code == 0) {
            dispatch({
                type: AGENT_REQUEST_FAIL,
            })
            err.response.data.msg && dispatch(setAlert(err.response.data.msg, "error"))
        }
    }

}

// Guest user
export const guestUser = ({ name, email, password, phoneNumber, city, userType, status, bookingID, seawalkdate, bookedSlot, travelers, totalAmount, travelersDetails }) => async (dispatch) => {
    const API = axios.create({
        baseURL: "https://www.seagoldseawalk.com",
    });

    try {
        const res = await API.post("https://www.seagoldseawalk.com/user_webservice/Login/guestUser", {
            name, email, phoneNumber, city, password, userType, status, bookingID, seawalkdate, bookedSlot, travelers, totalAmount, travelersDetails
        }).then((res) => {

            if (res?.data.data.instrumentResponse) {
                window.location.href = res.data.data.instrumentResponse.redirectInfo.url;
            }

        });
    } catch (err) {

        if (!err?.response) {
            console.log("no server response");
        }

        if (err.response.data.error_code == 0) {
            dispatch({
                type: BOOKING_FAIL,
                payload: { msg: err.response.data.msg, status: err.response.status }
            })
            err.response.data.msg && dispatch(setAlert(err.response.data.msg, "error"))
        }
    }

}

// register Agent
export const contact = ({ name, email, phoneNumber, message }) => async (dispatch) => {

    const API = axios.create({
        baseURL: "https://www.seagoldseawalk.com",
    });

    try {
        const res = await API.post("https://www.seagoldseawalk.com/user_webservice/Login/contact", {
            name, email, phoneNumber, message
        }).then((res) => {

            if (res?.data.error_code == 1) {
                dispatch({
                    type: ENQUIRY_SENT,
                })
                dispatch(setAlert("Enquiry Sent successfully", "success"))
            }
            dispatch(loadUser())
        });
    } catch (err) {

        if (!err?.response) {
            console.log("no server response");
        }

        if (err.response.data.error_code == 0) {
            dispatch({
                type: ENQUIRY_FAIL,
            })
            err.response.data.msg && dispatch(setAlert(err.response.data.msg, "error"))
        }
    }

}