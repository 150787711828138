import React, { useState, useCallback, useEffect } from "react";
import { Steps, Result, Modal, Button } from "antd";
import Step1Form from "./Step1Form";
import Step2Form from "./Step2Form";
import "./AgentAgreement.css";
import { connect } from "react-redux";
import { getAgent } from './../../actions/agent';
import { PropTypes } from 'prop-types';
import { Navigate, useParams } from 'react-router-dom';
import AgreementContent from "./AgreementContent";

const { Step } = Steps;

const AgentAgreement = ({ getAgent, agent: { agentDetails } }) => {

    const id = useParams();
    useEffect(() => {
        getAgent(id.agentId);
    }, [getAgent])

    const [data, setData] = useState({});
    const [currentStep, setCurrentStep] = useState(0);

    useEffect(() => {
        agentDetails?.status === "Agreement Accepted" || agentDetails?.status == 'Verified' && setCurrentStep(3)
    }, [agentDetails])

    const handleNextStep = useCallback(
        (data) => {
            setData(data);
            setCurrentStep(currentStep + 1);
        },
        [currentStep]
    );

    const handlePrevStep = useCallback(
        (data) => {
            setData(data);
            setCurrentStep(currentStep - 1);
        },
        [currentStep]
    );

    const handleSubmit = useCallback(
        (data) => {
            setData(data);
            console.log("Data", data);
            setCurrentStep(currentStep + 1);
            // Additional logic for form submission
        },
        [currentStep]
    );

    return (
        <div className="p-3 p-sm-5" id="agreement">
            <h3 className="text-center">
                Welcome to onboarding with <span>SEA GOLD SEA WALK</span>
            </h3>
            <div id="steps">
                <Steps current={1} className="mb-4">
                    {/* icon={<i class="fa-solid fa-lock fa-lg" style={{ color: "#00b8ab" }}></i>} */}
                    <Step status={currentStep > 0 ? "finish" : "process"} title="Verify OTP" />
                    <Step status={currentStep >= 2 && "finish"} title="Accept Agreement" />
                </Steps>
            </div>
            <div id="agent-agreement">

                {agentDetails?.status === "Agreement Accepted" || agentDetails?.status == 'Verified' ? (
                    <Result
                        status="success"
                        title="Successfully Accepted Agreement"
                        subTitle="Thanks for accepting the agreement and wait for onboarding details"
                    />
                ) : <>

                    {currentStep === 0 && (
                        <>
                            <h4 className="pb-4 text-center">Step {currentStep + 1} - OTP Verify</h4>
                            <Step1Form data={data} onSuccess={handleNextStep} />
                        </>
                    )}

                    {currentStep === 1 && (
                        <>
                            <h4 className="pb-4 text-center">Step {currentStep + 1} - Agreement, Terms & Conditions</h4>
                            <Step2Form
                                data={data}
                                onSuccess={handleSubmit}
                                onBack={handlePrevStep}
                            />
                        </>
                    )}
                </>
                }
            </div>
        </div>
    );
};

AgentAgreement.prototype = {
    getAgent: PropTypes.func.isRequired,
    agent: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    agent: state.agent
})

export default connect(mapStateToProps, { getAgent })(AgentAgreement);
