import { PDFDownloadLink, usePDF, BlobProvider, PDFViewer } from '@react-pdf/renderer';
import React, { Fragment, useRef, useState } from 'react';
import MyDocument from '../components/ui/DownloadPDF';
import "./ThankYou.scss";
import { Navigate, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { getBookingDetailsById } from '../actions/booking';
import { useEffect } from 'react';
import Spinner from './../components/ui/Spinner';
import { loadPaymentData } from './../actions/booking';
import spinner from "../components/ui/spinner.gif";
import { LazyLoadImage } from 'react-lazy-load-image-component';


const ThankYou = ({ getBookingDetailsById, loadPaymentData, auth: { isAuthenticated, user }, booking: { bookingDetails, loading, error } }) => {

  const id = useParams();
  const navigate = useNavigate();
  // console.log(id.thankyou)

  const [pdfUrl, setPdfUrl] = useState(null);

  useEffect(() => {
    loadPaymentData(id.thankyou, pdfUrl);
  }, [loadPaymentData, pdfUrl])

  // useEffect(() => {
  //     getBookingDetailsById(id.thankyou);
  // }, [getBookingDetailsById])

  // useEffect(() => {
  //     navigate(error && `/booking-failure/${id.thankyou}`)
  // }, [])

  const formdata = bookingDetails;

  const options = {
    timeZone: 'Asia/Kolkata', // Set the time zone to IST
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };

  const goBookingHistory = () => {
    localStorage.removeItem("booking details");
    navigate(isAuthenticated ? '/booking-history' : '/')
  }
  const gohome = () => {
    localStorage.removeItem("booking details");
    navigate('/')
  }
  const pdfDocument = <MyDocument bookingDetails={bookingDetails} user={user} />;
  const [instance, updateInstance] = usePDF({ document: <MyDocument bookingDetails={bookingDetails} user={user} /> });

  const handlePrint = (url) => {
    const printWindow = window.open(url);
    printWindow.print();
  };

  // console.log(pdf(pdfDocument).toBlob());

  return loading && bookingDetails == null ? <Fragment>
    <h5 className='text-center mt-5'>Don't close this page, until booking is successfull !</h5>
    <img
      src={spinner}
      style={{ width: "50px", margin: "auto", display: "block" }}
      alt="loading"
    ></img>
  </Fragment> : (
    formdata?.status === "paid" ?
      <Fragment>

        <div id='thank-you'>
          <img className='thank-you-bg' src='/images/thankyou/sea-bg.jpg' alt='sea-background'></img>
          <p className='text-center mb-3 m-sm-0'>Thank you for making payment</p>
          <div id='booking-success'>
            <h1 className='booking-success-text text-center p-0'><img className='success-img' src='/images/thankyou/success.png' alt='successfully-booked'></img> Your Booking Was Successful!</h1>
          </div>
          <div className='d-flex justify-content-center align-items-center'>
            <div>
              <PDFDownloadLink className="pt-3 download-bottom-btn" document={<MyDocument bookingDetails={formdata} user={user} />} fileName={formdata?.booking_id}>
                {({ blob, url, loading, error }) =>
                  loading ? 'Download PDF' : <button>Download PDF <img style={{ width: "25px" }} className='ms-2' src='/images/thankyou/download-white.png' alt='download'></img></button>
                }
              </PDFDownloadLink>
            </div>

            {/* <PDFDownloadLink
                        document={<MyDocument bookingDetails={formdata} user={user} />}
                        fileName="somename.pdf"
                        onClick={() => setPdfUrl(null)} // Reset the URL on each click
                    >
                        {({ blob, url, loading, error }) =>
                            loading
                                ? (() => {
                                    setPdfUrl(url); // Dispatch the action when loading
                                })()
                                : 'Download now!'
                        }
                    </PDFDownloadLink> */}


            {/* <BlobProvider document={<MyDocument bookingDetails={formdata} user={user} />}>
                        {({ blob, url, loading, error }) => {
                            if (loading) {
                                return <div>Loading...</div>;
                            }
                            if (error) {
                                return <div>Error: {error.message}</div>;
                            }

                            return (
                                <div>
                                    Print button
                                    <button className="print-pdf-button" onClick={() => handlePrint(url)}>
                                        Print PDF
                                    </button>
                                    Display PDF in an iframe
                                    <iframe title="PDF Viewer" width="100%" height="500px" src={url} />
                                </div>
                            );
                        }}
                    </BlobProvider> */}
          </div>

          <br></br>
          <div id='ticket-details'>
            <h2 className='text-center title'>Sea Walking in Andaman Islands</h2>
            <h5 className='m-0 text-center sub-title'>Ticket Booked for {formdata?.no_of_tourist} Persons</h5>
            <br></br>
            <div>
              <p className='m-0 text-center booking-id'>Booking ID: {formdata?.booking_id}</p>
              <p className='m-0 text-center booking-id'>Booked On: {new Intl.DateTimeFormat('en-US', options).format(new Date(formdata?.date_time))}</p>
            </div>
            <div className='row m-0 pt-3 pb-4'>
              <div className='col-sm-6 col-md-7 p-0'>
                <div id='user-details'>
                  <div className='row m-0'>
                    <div className='col-6 col-md-3'>
                      <p className='m-0'>Name:</p>
                    </div>
                    <div className='col-6 col-md-9'>
                      <p className='m-0'>{formdata?.user_name}</p>
                    </div>
                  </div>
                  <div className='row m-0'>
                    <div className='col-6 col-md-3'>
                      <p className='m-0'>Email ID:</p>
                    </div>
                    <div className='col-6 col-md-9'>
                      <p className='m-0'>{formdata?.email}</p>
                    </div>
                  </div>
                  <div className='row m-0'>
                    <div className='col-6 col-md-3'>
                      <p className='m-0'>Phone No:</p>
                    </div>
                    <div className='col-6 col-md-9'>
                      <p className='m-0'>{formdata?.phone_number}</p>
                    </div>
                  </div>
                  <div className='row m-0'>
                    <div className='col-6 col-md-3'>
                      <p className='m-0'>City:</p>
                    </div>
                    <div className='col-6 col-md-9'>
                      <p className='m-0'>{formdata?.city}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-sm-6 col-md-5 p-0'>
                <div id='booking-details'>
                  <div className='row m-0'>
                    <div className='col-6 col-sm-7'>
                      <p className='m-0'>Sea Walk Date:</p>
                    </div>
                    <div className='col-6 col-sm-5'>
                      <p className='m-0'>{formdata?.seawalk_date}</p>
                    </div>
                  </div>
                  <div className='row m-0'>
                    <div className='col-6 col-sm-7'>
                      <p className='m-0'>Slot (Sea Walk Time):</p>
                    </div>
                    <div className='col-6 col-sm-5'>
                      <p className='m-0'>{formdata?.booked_slot}</p>
                    </div>
                  </div>
                  <div className='row m-0'>
                    <div className='col-6 col-sm-7'>
                      <p className='m-0'>No.of Travellers:</p>
                    </div>
                    <div className='col-6 col-sm-5'>
                      <p className='m-0'>{formdata?.no_of_tourist} Person</p>
                    </div>
                  </div>
                  <div className='row m-0'>
                    <div className='col-6 col-sm-7'>
                      <p className='m-0'>Paid Amount:</p>
                    </div>
                    <div className='col-6 col-sm-5'>
                      <p className='m-0'>₹ {formdata?.total_amount}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='contact-details'>
              <p className='m-0 text-center' style={{ fontSize: "0.8rem" }}>Venue: Elephant Beach, Andaman and Nicobar Islands</p>
              <p className='m-0 text-center' style={{ fontSize: "0.8rem" }}>Contact: +91 99320-80340 for more details.</p>
            </div>
            <div className='d-flex flex-column justify-content-center align-items-center'>
              <img className="logo" src="/sea gold.jpg" alt='logo'></img>
            </div>
            <p className='m-0 text-center' style={{ fontSize: "0.8rem" }}>(For more details contact:+91 99320-80340)</p>
            <p className='m-0 text-center' style={{ fontSize: "0.8rem" }}>1st Floor, Sri Ram Complex, Opp: Old Taxi Stand, Aberdeen Bazaar,<br></br> Port Blair, Andaman and Nicobar Islands - 744101.</p>
          </div>
          <br></br>
          {
            isAuthenticated ? <div className='d-flex justify-content-center align-items-center'>
              <button className="customer-support" onClick={goBookingHistory}>View Booking History</button>
            </div> : <div className='d-flex justify-content-center align-items-center'>
              <button className="customer-support" onClick={goBookingHistory}>Go To Home Page</button>
            </div>
          }
          {/* <PDFViewer width="100%" height="700px">
                    <MyDocument id={id.thankyou} bookingDetails={user} />
                </PDFViewer> */}
        </div>
      </Fragment> :
      <Fragment>
        <div id='thank-you' className='h-100'>
          <LazyLoadImage className='thank-you-bg' src='/images/thankyou/sea-bg.jpg' alt='sea-background'></LazyLoadImage>
          <div id='booking-success'>
            <h1 className='booking-success-text text-center p-0'><LazyLoadImage className='success-img' src='/images/thankyou/failure.png' alt='successfully-booked'></LazyLoadImage>Oops! Payment Failed!</h1>
          </div>
          {/* <p className='text-center mb-3 m-sm-0' style={{ color: "red" }}>Your booking for {formdata.travelers} {formdata.travelers > 1 ? "persons" : "person"} on {(new Date(formdata.seawalkdate).toDateString())} has been failed.</p> */}
          <p className='text-center mb-3 m-sm-0'>Please try again</p>
          <br></br>
          <div className='d-flex justify-content-center align-items-center'>
            <button className="customer-support" onClick={gohome}>Go To Home Page</button>
          </div>
        </div>
      </Fragment>
  )
}

ThankYou.prototype = {
  getBookingDetailsById: PropTypes.func.isRequired,
  loadPaymentData: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  booking: PropTypes.object.isRequired,
  // setPdfUrl: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  booking: state.booking
})

export default connect(mapStateToProps, { getBookingDetailsById, loadPaymentData })(ThankYou);

